/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AgGridPositiveNumberFilterInputDto = {
    filter: number;
    filterTo?: number;
    type: AgGridPositiveNumberFilterInputDto.type;
};

export namespace AgGridPositiveNumberFilterInputDto {

    export enum type {
        EQUALS = 'equals',
        NOT_EQUAL = 'notEqual',
        GREATER_THAN = 'greaterThan',
        GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
        LESS_THAN = 'lessThan',
        LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
        IN_RANGE = 'inRange',
    }


}

