<template>
  <v-card width="100%" variant="tonal" class="base-info-wrapper" data-cy="base-info">
    <v-row no-gutters>
      <v-card-title class="w-100 d-flex justify-space-between">
        {{ $t('collaboratorProfilesPage.baseInfo') }}
        <c-copy-button data-cy="collaborator-profile-copy-base-info" :get-data="getCopyData" />
      </v-card-title>
      <v-divider />

      <v-card-text>
        <h2>{{ getCollaboratorFullName(collaboratorProfile) }}</h2>

        <v-row no-gutters class="text-body-2 align-center">
          <v-col lg="6" cols="12">
            <p>
              <span v-for="position in collaboratorProfile.collaborator.positions" :key="position.uuid">
                <v-chip size="small"> {{ position.name }}&nbsp;</v-chip>
              </span>
            </p>
          </v-col>

          <v-col lg="6" cols="12">
            <v-checkbox
              v-model="willingnessToLeadTeam"
              density="compact"
              hide-details
              data-cy="lead-checkbox"
              label="willingness to lead the team"
              @update:model-value="emitCollaboratorChange"
            />
          </v-col>

          <v-col lg="6" cols="12">
            <v-responsive max-width="220px">
              <div class="d-flex ga-1 align-center pa-0">
                <label for="experience-input">{{ $t('collaboratorProfilesPage.experience') }}</label>
                <v-responsive class="ma-0 pa-0" max-width="70px" height="40px">
                  <v-text-field
                    id="experience-input"
                    v-model.number="yearsOfExperience"
                    type="number"
                    density="compact"
                    @keyup="emitCollaboratorChange"
                    @change="emitCollaboratorChange"
                  />
                </v-responsive>
              </div>
            </v-responsive>
          </v-col>

          <v-col lg="6" cols="12">
            <v-checkbox
              v-model="experienceInLeadingTeam"
              density="compact"
              hide-details
              label="experience in leading a team"
              @update:model-value="emitCollaboratorChange"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import { ref, toRefs, watch } from 'vue';
import { CollaboratorProfileOutputDto } from 'generated-api/erp';
import { getCollaboratorFullName } from './utils';
import { useCopyData } from '@/composables/useCopyData';
import CCopyButton from '@/components/Common/CCopyButton.vue';

const { getCollaboratorProfileBaseInfoCopyData } = useCopyData();

const props = defineProps<{
  collaboratorProfile: CollaboratorProfileOutputDto;
}>();

const emit = defineEmits(['updateProfile']);

const { collaboratorProfile } = toRefs(props);

const willingnessToLeadTeam = ref(collaboratorProfile.value.willingnessToLeadTeam || false);
const experienceInLeadingTeam = ref(collaboratorProfile.value.experienceInLeadingTeam || false);
const yearsOfExperience = ref(collaboratorProfile.value.yearsOfExperience || 0);

const emitCollaboratorChange = () => {
  if (!collaboratorProfile.value) {
    collaboratorProfile.value = {
      willingnessToLeadTeam: willingnessToLeadTeam.value,
      experienceInLeadingTeam: experienceInLeadingTeam.value,
      yearsOfExperience: yearsOfExperience.value,
    } as CollaboratorProfileOutputDto;
  } else {
    collaboratorProfile.value.willingnessToLeadTeam = willingnessToLeadTeam.value;
    collaboratorProfile.value.experienceInLeadingTeam = experienceInLeadingTeam.value;
    collaboratorProfile.value.yearsOfExperience = yearsOfExperience.value;
  }

  emit('updateProfile', collaboratorProfile.value);
};

const getCopyData = () => getCollaboratorProfileBaseInfoCopyData(collaboratorProfile.value);

watch(
  () => props.collaboratorProfile,
  newProfile => {
    willingnessToLeadTeam.value = newProfile.willingnessToLeadTeam || false;
    experienceInLeadingTeam.value = newProfile.experienceInLeadingTeam || false;
    yearsOfExperience.value = newProfile.yearsOfExperience || 0;
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
.base-info-wrapper {
  .ga-1 {
    gap: 1rem;
  }
}
</style>
