/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnumsOutputDto } from '../models/EnumsOutputDto';
import type { OkOneResponse } from '../models/OkOneResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EnumsService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static getAll({
        tenant,
        company,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
    }): CancelablePromise<({
        data: EnumsOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{tenant}/{company}/erp/enums',
            path: {
                'tenant': tenant,
                'company': company,
            },
        });
    }

}
