import { useConfig } from '@/composables/useConfig';
import { useLogger } from '@/composables/useLogger';
import { TFPL_ID_TOKEN } from '@/constants';
import { WSEventName } from '@/types/webSocketEvents/enums';
import { io } from 'socket.io-client';
import { Socket } from 'socket.io-client/build/esm/socket';
import { ref } from 'vue';

export const socketEvents = ref<Record<string, unknown>[]>([]);
export const WSError = {
  NO_SOCKET: 'NO_SOCKET',
} as const;

let socket: Socket | null = null;

export const useWebSocket = () => {
  const { getConfig } = useConfig();
  const { logger } = useLogger();

  /**
   * Re-establishes a WebSocket connection with updated configuration.
   *
   * This function will disconnect the current WebSocket if it exists,
   * and create a new WebSocket instance with parameters fetched from
   * the configuration. It limits the stored socket events to the last 5.
   * The new WebSocket connection does not auto-connect on creation.
   */
  const createSocket = () => {
    socket = io(getConfig().WEBSOCKET_URL, {
      transports: ['websocket'],
      autoConnect: false,
      query: {
        tenant: getConfig().TENANT,
      },
      auth: {
        [TFPL_ID_TOKEN]: null,
      },
    });

    socket.onAny((event: string, data: unknown) => {
      socketEvents.value.push({ [event]: data });
      socketEvents.value = socketEvents.value.slice(-5);
    });
    logger.debug('WebSocket is recreated');
  };

  const destroySocket = () => {
    if (!socket) {
      logger.warn("Socket doesn't exists. Aborting socket destroy...");
      return WSError.NO_SOCKET;
    }

    socket.disconnect();
    socket.off();
    socket = null;
  };

  const addListener = <D extends { data: { [k: string]: unknown } }>(
    eventName: WSEventName,
    handler: (data: D) => void,
  ): void | keyof typeof WSError => {
    if (!socket) {
      logger.error("Socket doesn't exists. Aborting socket message handler...");
      return WSError.NO_SOCKET;
    }

    socket.on(eventName, handler);
  };

  const removeListener = (eventName: WSEventName): void | keyof typeof WSError => {
    if (!socket) {
      logger.error("Socket doesn't exists. Aborting ...");
      return WSError.NO_SOCKET;
    }

    socket.off(eventName);
  };

  /**
   * Authenticates and initiates a WebSocket connection.
   *
   * The `authAndConnect` function takes a token string as an argument,
   * assigns it to the authentication property of the WebSocket if it is initialized,
   * and establishes a connection. It also logs the connection status.
   *
   * @param {string} token - The authentication token used to authenticate the WebSocket connection.
   */
  const authAndConnect = (token: string) => {
    if (socket) {
      if (typeof socket.auth === 'object' && socket.auth !== null) {
        socket.auth[TFPL_ID_TOKEN as keyof typeof socket.auth] = token;
      }
      socket.connect();
      logger.debug('WebSocket is connected');
    } else {
      logger.warn('WebSocket is not initialized');
    }
  };
  /**
   * Retrieves the WebSocket instance.
   *
   * This function returns the WebSocket instance that has been instantiated
   * and is being used for real-time, bidirectional communication between
   * the client and server.
   *
   * @returns {Socket} The WebSocket instance in use.
   */
  const getSocket = (): Socket | null => {
    return socket;
  };

  return { getSocket, createSocket, destroySocket, authAndConnect, addListener, removeListener };
};
