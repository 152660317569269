/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PersonalSettingsOneResponseDto } from '../models/PersonalSettingsOneResponseDto';
import type { UpdatePersonalSettingsDto } from '../models/UpdatePersonalSettingsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TfAgwPersonalSettingsService {

    /**
     * @returns PersonalSettingsOneResponseDto
     * @throws ApiError
     */
    public static getPersonalSettings({
        tenant,
    }: {
        /**
         * Code of firebase tenant, use "public" for skip authentication
         */
        tenant: string,
    }): CancelablePromise<PersonalSettingsOneResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{tenant}/personal-settings',
            path: {
                'tenant': tenant,
            },
        });
    }

    /**
     * @returns PersonalSettingsOneResponseDto
     * @throws ApiError
     */
    public static updatePersonalSettings({
        tenant,
        requestBody,
    }: {
        /**
         * Code of firebase tenant, use "public" for skip authentication
         */
        tenant: string,
        requestBody: UpdatePersonalSettingsDto,
    }): CancelablePromise<PersonalSettingsOneResponseDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/{tenant}/personal-settings',
            path: {
                'tenant': tenant,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
