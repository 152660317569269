/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type WorkLogIssueUpdateDto = {
    issueStatus: WorkLogIssueUpdateDto.issueStatus;
};

export namespace WorkLogIssueUpdateDto {

    export enum issueStatus {
        NEW = 'NEW',
        MANUAL_RESOLVED = 'MANUAL_RESOLVED',
    }


}

