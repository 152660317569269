import { ref } from 'vue';
import { CollaboratorProfileOutputDto, CollaboratorProfilesService } from 'generated-api/erp';
import { useConfig } from '@/composables/useConfig';
import { useSnack } from '@/composables/useSnack';
import { useLogger } from '@/composables/useLogger';
import { useI18n } from 'vue-i18n';
import { useActiveCompany } from '@/composables/useActiveCompany';

const { getConfig } = useConfig();
const { addSnack } = useSnack();
const { getActiveCompany } = useActiveCompany();

export const useCollaboratorProfiles = () => {
  const { logger } = useLogger();
  const { t } = useI18n();

  const loading = ref(false);
  const collaboratorProfiles = ref<CollaboratorProfileOutputDto[]>([]);

  const fetchCollaboratorsAndProjects = async () => {
    loading.value = true;
    const baseConfig = {
      tenant: getConfig().TENANT,
      company: getActiveCompany().code,
    };

    try {
      collaboratorProfiles.value = [];

      const response = await CollaboratorProfilesService.getAll({
        ...baseConfig,
      });
      collaboratorProfiles.value = response.data;

      for (const profile of collaboratorProfiles.value) {
        profile.collaborator.certifications = profile.collaborator.certifications?.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
      }
    } catch (e) {
      logger.error(e);
      addSnack({
        id: 'toast.error',
        message: t('toast.error'),
      });
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    collaboratorProfiles,
    fetchCollaboratorsAndProjects,
  };
};
