export const useHtml = () => {
  const htmlToPlainText = (data: string | undefined) => {
    if (!data) {
      return '';
    }

    const document = new DOMParser().parseFromString(data, 'text/html');
    return document.body.innerHTML
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\/p>/gi, '\n')
      .replace(/<\/li>/gi, '\n')
      .replace(/<\/div>/gi, '\n')
      .replace(/<\/h[1-6]>/gi, '\n\n')
      .replace(/<\/?[^>]+(>|$)/g, '')
      .trim();
  };

  return { htmlToPlainText };
};
