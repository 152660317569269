/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OkManyResponse } from '../models/OkManyResponse';
import type { OkOneResponse } from '../models/OkOneResponse';
import type { SubmenuItemCreateDto } from '../models/SubmenuItemCreateDto';
import type { SubmenuItemOutputDto } from '../models/SubmenuItemOutputDto';
import type { SubmenuItemUpdateDto } from '../models/SubmenuItemUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubmenuItemService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static getAll({
        tenant,
        company,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
    }): CancelablePromise<({
        data: Array<SubmenuItemOutputDto>;
    } & OkManyResponse)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{tenant}/{company}/erp/submenu-items',
            path: {
                'tenant': tenant,
                'company': company,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static post({
        tenant,
        company,
        requestBody,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        requestBody: SubmenuItemCreateDto,
    }): CancelablePromise<({
        data: SubmenuItemOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{tenant}/{company}/erp/submenu-items',
            path: {
                'tenant': tenant,
                'company': company,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static getOne({
        tenant,
        company,
        id,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        id: number,
    }): CancelablePromise<({
        data: SubmenuItemOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{tenant}/{company}/erp/submenu-items/{id}',
            path: {
                'tenant': tenant,
                'company': company,
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static put({
        tenant,
        company,
        id,
        requestBody,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        id: number,
        requestBody: SubmenuItemUpdateDto,
    }): CancelablePromise<({
        data: SubmenuItemOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/{tenant}/{company}/erp/submenu-items/{id}',
            path: {
                'tenant': tenant,
                'company': company,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static remove({
        tenant,
        company,
        id,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        id: number,
    }): CancelablePromise<({
        data: SubmenuItemOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/{tenant}/{company}/erp/submenu-items/{id}',
            path: {
                'tenant': tenant,
                'company': company,
                'id': id,
            },
        });
    }

}
