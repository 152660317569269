import moment from 'moment';

import { DATE_FORMAT_STRING, DATETIME_FORMAT_STRING, DATE_MONTH_FORMAT_STRING } from '../constants';

export const useFormatters = () => {
  // Money formatters
  const toNiceCzk = (value?: number, showFraction = false) => {
    if (value === undefined || isNaN(value)) {
      return '-';
    }

    const formatter = new Intl.NumberFormat('cs', {
      style: 'decimal',
      minimumFractionDigits: showFraction ? 2 : 0,
      maximumFractionDigits: showFraction ? 2 : 0,
    });

    return formatter
      .format(value)
      .replace(/\u00a0/g, ' ')
      .trim();
  };

  // Number formatters
  const toFloat = (value: string | number) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    const formatter = new Intl.NumberFormat('cs', {
      style: 'decimal',
      minimumFractionDigits: 0,
    });

    return formatter.format(parsedValue);
  };

  const toDecimal = (value: string | number) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(parsedValue)) {
      return '-';
    }

    const formatter = new Intl.NumberFormat('cs', {
      style: 'decimal',
      minimumFractionDigits: 2,
    });

    return formatter.format(parsedValue);
  };

  const toPercentage = (value: string | number) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(parsedValue)) {
      return '-';
    }

    const formatter = new Intl.NumberFormat('cs', {
      style: 'decimal',
      minimumFractionDigits: 2,
    });

    return `${formatter.format(parsedValue)} %`;
  };

  const toRoundedPercentage = (value: string | number) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(parsedValue)) {
      return '-';
    }

    const formatter = new Intl.NumberFormat('cs', {
      style: 'decimal',
      maximumFractionDigits: 1,
    });

    return `${formatter.format(parsedValue)} %`;
  };

  const toInteger = (value: string | number) => {
    const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(parsedValue)) {
      return '-';
    }

    const formatter = new Intl.NumberFormat('cs', {
      style: 'decimal',
      maximumFractionDigits: 0,
    });

    return formatter.format(parsedValue);
  };

  // Date formatters
  const toStandardDate = (value: string) => {
    return value ? moment(value).format(DATE_FORMAT_STRING) : '-';
  };

  const toStandardDateTime = (value: string) => {
    return value ? moment(value).format(DATETIME_FORMAT_STRING) : '-';
  };

  const toStandardMonthDate = (value: string) => {
    return value ? moment(value).format(DATE_MONTH_FORMAT_STRING) : '-';
  };

  return {
    toFloat,
    toDecimal,
    toPercentage,
    toRoundedPercentage,
    toInteger,
    toNiceCzk,
    toStandardDate,
    toStandardDateTime,
    toStandardMonthDate,
  };
};
