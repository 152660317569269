/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DisplayNameDto } from './DisplayNameDto';

export type InvoiceOutputDto = {
    _created: string;
    _updated: string;
    id: number;
    uuid: string;
    invoiceNumber: string;
    taxPointDate: string;
    dateCreated: string;
    dateOfPayment: string;
    variableSymbol: string;
    datePaid?: string;
    priceWithoutVat: number;
    expectedAmount: number;
    project: DisplayNameDto;
    currency: string;
    vat: number;
    note: string;
    type: InvoiceOutputDto.type;
    status: InvoiceOutputDto.status;
    order?: DisplayNameDto;
    organization: DisplayNameDto;
    standardInvoice: DisplayNameDto;
    proformaInvoices: Array<DisplayNameDto>;
};

export namespace InvoiceOutputDto {

    export enum type {
        PROFORMA_INVOICE = 'PROFORMA_INVOICE',
        STANDARD_INVOICE = 'STANDARD_INVOICE',
    }

    export enum status {
        PAID = 'PAID',
        PENDING = 'PENDING',
        UNPAID = 'UNPAID',
    }


}

