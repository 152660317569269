import { TfAgwPersonalSettingsService } from 'generated-api/agw/services/TfAgwPersonalSettingsService';
import { useLogger } from '@/composables/useLogger';
import { useConfig } from '@/composables/useConfig';

export const UPDATE_HOME_FAVORITE_ITEM = 'UPDATE_HOME_FAVORITE_ITEM';
export const SAVE_PERSONAL_SETTINGS = 'SAVE_PERSONAL_SETTINGS';
export const SET_PERSONAL_SETTINGS = 'SET_PERSONAL_SETTINGS';
export const TOGGLE_FAVOURITE_MENU_ITEMS = 'TOGGLE_FAVOURITE_MENU_ITEMS';
export const GET_PERSONAL_SETTINGS = 'GET_PERSONAL_SETTINGS';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_SELECTED_TENANT_CODE_SETTINGS = 'SET_SELECTED_TENANT_CODE_SETTINGS';
export const SET_OPEN_IN_NEW_WINDOW = 'SET_OPEN_IN_NEW_WINDOW';
export const SET_AG_GRID_TABLES_COLUMNS_DEF = 'SET_AG_GRID_TABLES_COLUMNS_DEF';
export const SET_AG_GRID_TABLES_OPTIONS = 'SET_AG_GRID_TABLES_OPTIONS';

const { logger } = useLogger();
const { getConfig } = useConfig();

export const DEFAULT_PERSONAL_SETTINGS = {
  favoriteMenuItems: [],
  darkMode: false,
  openInNewWindow: false,
  agGridTablesColumnsDef: {},
  agGridTablesOptions: {},
  selectedTenantCode: 'TF',
};

const state = {
  ...DEFAULT_PERSONAL_SETTINGS,
};

const addOrRemove = (arr, item) => (arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item]);

const mutations = {
  [TOGGLE_FAVOURITE_MENU_ITEMS]: (state, { favoriteMenuItemId }) => {
    if (!state.favoriteMenuItems) {
      state.favoriteMenuItems = [];
    }
    state.favoriteMenuItems = addOrRemove(state.favoriteMenuItems, favoriteMenuItemId);
  },
  [SET_DARK_MODE]: (state, payload) => {
    state.darkMode = payload.darkMode;
  },
  [SET_SELECTED_TENANT_CODE_SETTINGS]: (state, payload) => {
    state.selectedTenantCode = payload.tenantCode;
  },
  [SET_OPEN_IN_NEW_WINDOW]: (state, payload) => {
    state.openInNewWindow = payload.openInNewWindow;
  },
  [SET_AG_GRID_TABLES_COLUMNS_DEF]: (state, payload) => {
    state.agGridTablesColumnsDef = {
      ...state.agGridTablesColumnsDef,
      ...payload,
    };
  },
  [SET_AG_GRID_TABLES_OPTIONS]: (state, payload) => {
    state.agGridTablesOptions = {
      ...state.agGridTablesOptions,
      ...payload,
    };
  },
  [SET_PERSONAL_SETTINGS]: (
    state,
    { favoriteMenuItems, darkMode, openInNewWindow, agGridTablesColumnsDef, agGridTablesOptions, selectedTenantCode },
  ) => {
    state.favoriteMenuItems = favoriteMenuItems;
    state.darkMode = darkMode;
    state.openInNewWindow = openInNewWindow;
    state.agGridTablesColumnsDef = agGridTablesColumnsDef;
    state.agGridTablesOptions = agGridTablesOptions;
    state.selectedTenantCode = selectedTenantCode;
  },
};

const actions = {
  [SAVE_PERSONAL_SETTINGS]: async ({ state }) => {
    try {
      await TfAgwPersonalSettingsService.updatePersonalSettings({
        tenant: getConfig().TENANT,
        requestBody: {
          settings: { ...state },
          app: 'ERP',
        },
      });
    } catch (e) {
      logger.error(e);
    }
  },
  [GET_PERSONAL_SETTINGS]: async ({ commit }) => {
    try {
      const response = await TfAgwPersonalSettingsService.getPersonalSettings({
        tenant: getConfig().TENANT,
      });
      if (response.data.erpSettings) {
        commit(SET_PERSONAL_SETTINGS, response.data.erpSettings);
      }
    } catch (e) {
      logger.error(e);
    }
  },
};

const getters = {
  personalSettings: state => state,
  darkModeActive: state => state.darkMode,
  openInNewWindowActive: state => state.openInNewWindow,
  agGridTablesColumnsDef: state => state.agGridTablesColumnsDef,
  agGridTablesOptions: state => state.agGridTablesOptions,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
