const formatTime = (date: Date): string => {
  return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

const logger = {
  debug(msg: unknown, ...rest: unknown[]) {
    console.groupCollapsed(`%c[${formatTime(new Date())}][DEBUG] ${msg}`, 'color:lightblue');
    console.log(...rest);
    console.trace('call stack');
    console.groupEnd();
  },
  info(msg: unknown, ...rest: unknown[]) {
    console.groupCollapsed(`%c[${formatTime(new Date())}] [INFO] ${msg}`, 'color:yellow');
    console.log(...rest);
    console.trace('call stack');
    console.groupEnd();
  },
  warn(msg: unknown, ...rest: unknown[]) {
    console.groupCollapsed(`%c[${formatTime(new Date())}] [WARN] ${msg}`, 'color:orange');
    console.log(...rest);
    console.trace('call stack');
    console.groupEnd();
  },
  error(msg: unknown, ...rest: unknown[]) {
    console.groupCollapsed(`%c[${formatTime(new Date())}][ERROR] ${msg}`, 'color:red');
    console.log(...rest);
    console.trace('call stack');
    console.groupEnd();
  },
};

export const useLogger = () => {
  return {
    logger,
  };
};
