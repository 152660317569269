import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import 'vuetify/styles';

import { createVuetify } from 'vuetify';
import * as labs from 'vuetify/labs/components';
// colors currently are not configured for ts import
// import colors from 'vuetify/lib/util/colors'

export default createVuetify({
  defaults: {
    VTextField: {
      variant: 'outlined',
    },
    VTextarea: {
      variant: 'outlined',
    },
    VAutocomplete: {
      variant: 'outlined',
    },
    VSelect: {
      variant: 'outlined',
    },
  },
  components: {
    ...labs,
  },
  theme: {
    variations: {
      colors: ['primary', 'error', 'warning'],
      lighten: 5,
      darken: 5,
    },
    themes: {
      dark: {
        colors: {
          primary: '#1976D2', //blue-darken-2
          error: '#D32F2F', //red-darken-2
          success: '#388E3C', //green-darken-2
          warning: '#F57C00', // orange-darken-2
        },
      },
      light: {
        colors: {
          primary: '#42A5F5', //blue-lighten-1
        },
      },
    },
  },
});
