<template>
  <v-card v-if="mounted" width="100%" variant="tonal" class="collaborator-projects-wrapper">
    <v-row no-gutters>
      <v-card-title class="w-100 d-flex justify-space-between">
        {{ $t('collaboratorProfilesPage.projects') }}
        <c-copy-button data-cy="collaborator-profile-copy-projects" :get-data="getCopyData" />
      </v-card-title>
      <v-divider />

      <v-row v-if="isLoading" class="ma-4">
        <v-col class="text-center"><c-spinner /></v-col>
      </v-row>
      <v-card-text v-if="!isLoading">
        <div v-for="(projectItem, index) in projects" :key="projectItem.project.id">
          <v-row>
            <v-col lg="4" cols="12">
              <div class="project-info flex-column flex-xl-row">
                <v-card-title class="pl-0">
                  <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                  {{ projectItem.project.abbreviation }}: {{ projectItem.project.name }}
                </v-card-title>
              </div>
              <div>
                <v-chip size="small" class="mr-3">
                  {{ $t('collaboratorProfilesPage.monthsOnProject') }} {{ Math.round(projectItem.monthsOnProject) }}
                </v-chip>
                <v-chip size="small">
                  {{ $t('collaboratorProfilesPage.workedMD') }} {{ Math.round(projectItem.plannedCapacity) }}
                </v-chip>
              </div>
              <p class="mt-2">{{ projectItem.project.projectMainGoal }}</p>
            </v-col>

            <v-col lg="8" cols="12" class="pb-0">
              <v-row class="d-flex align-start flex-wrap">
                <v-col
                  v-for="category in data[projectItem.project.id]?.categories"
                  :key="category.name"
                  cols="12"
                  md="4"
                  class="category-col"
                >
                  <h5 class="category-title">{{ categoryDisplayNames[category.name] }}</h5>
                  <div v-for="categoryItem in category.items" :key="categoryItem.name">
                    <!-- Tooltip for each category item -->
                    <v-tooltip location="bottom">
                      <template #activator="{ props }">
                        <v-checkbox
                          v-model="categoryItem.active"
                          :disabled="
                            !hasLoggedUserPermission('ERP_COLLABORATOR_PROFILES', ['UPDATE_ALL', 'UPDATE_OWN'])
                          "
                          :label="actionDisplayNames[categoryItem.name]"
                          density="compact"
                          hide-details
                          v-bind="props"
                        />
                      </template>
                      <span>{{ getTooltip(category.name, categoryItem.name) }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider v-if="index < projects.length - 1" class="my-6" />
        </div>
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import {
  CollaboratorOutputDto,
  CollaboratorProfileOutputDto,
  CollaboratorProjectWorkTypeOutputDto,
  CollaboratorProjectWorkTypesService,
  ProjectWithCapacityDto,
} from 'generated-api/erp';
import { useConfig } from '@/composables/useConfig';
import { useI18n } from 'vue-i18n';
import { useAuthorization } from '@/composables/auth/useAuthorization';
import { useLogger } from '@/composables/useLogger';
import { useSnack } from '@/composables/useSnack';
import _ from 'lodash';
import CSpinner from '@/components/Common/CSpinner.vue';
import { useActiveCompany } from '@/composables/useActiveCompany';
import { useCopyData } from '@/composables/useCopyData';
import CCopyButton from '@/components/Common/CCopyButton.vue';
import { actionDisplayNames, categoryDisplayNames } from './enums';
import type { ActionCategoryRecord, ActionItem, Category, ProjectsDataEntry } from './types';

const { getCollaboratorProfileProjectsCopyData } = useCopyData();

const properties = defineProps<{
  collaboratorProfile: CollaboratorProfileOutputDto;
}>();

const { getConfig } = useConfig();
const { t } = useI18n();
const { hasLoggedUserPermission } = useAuthorization();
const { logger } = useLogger();
const { addSnack } = useSnack();
const { getActiveCompany } = useActiveCompany();

const mounted = ref(false);
const collaborator = ref<CollaboratorOutputDto | null>(null);
const projects = ref<ProjectWithCapacityDto[]>([]);
const workTypes = ref<CollaboratorProjectWorkTypeOutputDto[]>([]);

const data = ref<ProjectsDataEntry>({});
const originalData = ref<ProjectsDataEntry>({});

const isUpdated = computed(() => {
  return !_.isEqual(data.value, originalData.value);
});

const isLoading = ref(false);

const getTooltip = (categoryName: string, itemName: string): string => {
  const tooltips: Record<string, Record<string, string>> = {
    designAndArchitecture: {
      softwareArchitecture: t('collaboratorProfilesPage.designAndArchitecture.softwareArchitecture'),
      databaseArchitecture: t('collaboratorProfilesPage.designAndArchitecture.databaseArchitecture'),
      technologySelection: t('collaboratorProfilesPage.designAndArchitecture.technologySelection'),
      technicalLeadership: t('collaboratorProfilesPage.designAndArchitecture.technicalLeadership'),
    },
    development: {
      frontend: t('collaboratorProfilesPage.development.frontend'),
      backend: t('collaboratorProfilesPage.development.backend'),
      database: t('collaboratorProfilesPage.development.database'),
      api: t('collaboratorProfilesPage.development.api'),
    },
    testingAndQA: {
      unitTesting: t('collaboratorProfilesPage.testingAndQA.unitTesting'),
      e2eTesting: t('collaboratorProfilesPage.testingAndQA.e2eTesting'),
      codeReview: t('collaboratorProfilesPage.testingAndQA.codeReview'),
      bugFixing: t('collaboratorProfilesPage.testingAndQA.bugFixing'),
    },
    maintenance: {
      refactoring: t('collaboratorProfilesPage.maintenance.refactoring'),
      support: t('collaboratorProfilesPage.maintenance.support'),
    },
    collaborationAndCommunication: {
      customerCommunication: t('collaboratorProfilesPage.collaborationAndCommunication.customerCommunication'),
      teamCollaboration: t('collaboratorProfilesPage.collaborationAndCommunication.teamCollaboration'),
      documentation: t('collaboratorProfilesPage.collaborationAndCommunication.documentation'),
    },
    projectManagement: {
      estimationAndPlanning: t('collaboratorProfilesPage.projectManagement.estimationAndPlanning'),
      taskManagement: t('collaboratorProfilesPage.projectManagement.taskManagement'),
      monitoringAndReporting: t('collaboratorProfilesPage.projectManagement.monitoringAndReporting'),
    },
  };

  return tooltips[categoryName]?.[itemName] || '';
};

const initializeProjectData = (
  projectsList: ProjectWithCapacityDto[],
  workTypesData: CollaboratorProjectWorkTypeOutputDto[],
) => {
  projectsList.forEach(projectItem => {
    const projectId = projectItem.project.id;
    const workTypeForProject = workTypesData.find(wt => wt.projectId === projectId);

    if (!data.value[projectId]) {
      data.value[projectId] = {
        categories: [
          {
            name: 'designAndArchitecture',
            items: [
              { name: 'softwareArchitecture', active: false },
              { name: 'databaseArchitecture', active: false },
              { name: 'technologySelection', active: false },
              { name: 'technicalLeadership', active: false },
            ],
          },
          {
            name: 'development',
            items: [
              { name: 'frontend', active: false },
              { name: 'backend', active: false },
              { name: 'database', active: false },
              { name: 'api', active: false },
            ],
          },
          {
            name: 'testingAndQA',
            items: [
              { name: 'unitTesting', active: false },
              { name: 'e2eTesting', active: false },
              { name: 'codeReview', active: false },
              { name: 'bugFixing', active: false },
            ],
          },
          {
            name: 'maintenance',
            items: [
              { name: 'refactoring', active: false },
              { name: 'support', active: false },
            ],
          },
          {
            name: 'collaborationAndCommunication',
            items: [
              { name: 'customerCommunication', active: false },
              { name: 'teamCollaboration', active: false },
              { name: 'documentation', active: false },
            ],
          },
          {
            name: 'projectManagement',
            items: [
              { name: 'estimationAndPlanning', active: false },
              { name: 'taskManagement', active: false },
              { name: 'monitoringAndReporting', active: false },
            ],
          },
        ],
      };
    }

    if (workTypeForProject) {
      const categories = data.value[projectId].categories;
      categories.forEach((category: Category) => {
        if (!workTypeForProject.actions.categories) {
          return;
        }
        const apiCategory = workTypeForProject.actions.categories.find(
          (cat: ActionCategoryRecord) => Object.keys(cat)[0] === category.name,
        );

        if (apiCategory) {
          const apiActions = apiCategory[category.name];
          category.items.forEach((item: ActionItem) => {
            const apiItem = apiActions.find((act: ActionItem) => act.name === item.name);
            if (apiItem) {
              item.active = apiItem.active;
            }
          });
        }
      });
    }
  });

  originalData.value = _.cloneDeep(data.value);
};

const saveData = async () => {
  if (!collaborator.value || !projects.value.length) return;

  const collaboratorValue = collaborator.value;

  const collaboratorData = {
    ...collaboratorValue,
    projects: projects.value.map(projectItem => ({
      ...projectItem,
      actions: {
        categories: data.value[projectItem.project.id]?.categories.map((category: Category) => {
          return {
            [category.name]: category.items.map((action: ActionItem) => ({
              name: action.name,
              active: action.active,
            })),
          };
        }),
      },
    })),
  };

  const payloads = collaboratorData.projects.map(project => ({
    collaboratorId: collaboratorValue.id,
    projectId: project.project.id,
    actions: project.actions,
  }));

  try {
    if (workTypes.value.length > 0) {
      await CollaboratorProjectWorkTypesService.update({
        tenant: getConfig().TENANT,
        company: getActiveCompany().code,
        id: collaboratorValue.id,
        requestBody: payloads,
      });
    } else {
      await CollaboratorProjectWorkTypesService.create({
        tenant: getConfig().TENANT,
        company: getActiveCompany().code,
        id: collaboratorValue.id,
        requestBody: payloads,
      });
    }

    originalData.value = _.cloneDeep(data.value);
  } catch (error) {
    console.error('Error during API call:', error);
  }
};

const getCopyData = () => getCollaboratorProfileProjectsCopyData(projects.value, originalData.value);

defineExpose({
  saveData,
  isUpdated,
  projects,
  projectsData: originalData,
});

const fetchData = async () => {
  // Reset the data object
  data.value = {};
  isLoading.value = true;

  try {
    const res = await CollaboratorProjectWorkTypesService.getList({
      tenant: getConfig().TENANT,
      company: getActiveCompany().code,
      id: properties.collaboratorProfile.collaborator.id,
    });

    const responseData = res.data;
    const projectsSorted = responseData.projects.sort((a, b) =>
      Number(a.plannedCapacity) > Number(b.plannedCapacity) ? -1 : 1,
    );

    collaborator.value = responseData.collaborator;
    projects.value = projectsSorted;
    workTypes.value = responseData.workTypes;

    initializeProjectData(projects.value, workTypes.value);

    isLoading.value = false;
  } catch (e) {
    logger.error(e);
    addSnack({
      id: 'toast.error',
      message: t('toast.error'),
    });
  }
};

onMounted(() => {
  fetchData();
  mounted.value = true;
});

watch(
  () => properties.collaboratorProfile.uuid,
  () => {
    fetchData();
  },
);
</script>

<style lang="scss">
.project-info {
  display: flex;
  align-items: center;
}

.category-col {
  padding: 8px;
}
.category-title {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
  min-height: 20px;
}
</style>
