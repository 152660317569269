/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollaboratorProjectWorkTypeCreateDto } from '../models/CollaboratorProjectWorkTypeCreateDto';
import type { CollaboratorProjectWorkTypeOutputDto } from '../models/CollaboratorProjectWorkTypeOutputDto';
import type { CollaboratorWithProjectsOutputDto } from '../models/CollaboratorWithProjectsOutputDto';
import type { OkManyResponse } from '../models/OkManyResponse';
import type { OkOneResponse } from '../models/OkOneResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CollaboratorProjectWorkTypesService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static getList({
        tenant,
        company,
        id,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        id: number,
    }): CancelablePromise<({
        data: CollaboratorWithProjectsOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{tenant}/{company}/erp/project-work-types/{id}',
            path: {
                'tenant': tenant,
                'company': company,
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static create({
        tenant,
        company,
        id,
        requestBody,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        id: number,
        requestBody: Array<CollaboratorProjectWorkTypeCreateDto>,
    }): CancelablePromise<({
        data: Array<CollaboratorProjectWorkTypeOutputDto>;
    } & OkManyResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{tenant}/{company}/erp/project-work-types/{id}',
            path: {
                'tenant': tenant,
                'company': company,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static update({
        tenant,
        company,
        id,
        requestBody,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        id: number,
        requestBody: Array<CollaboratorProjectWorkTypeCreateDto>,
    }): CancelablePromise<({
        data: Array<CollaboratorProjectWorkTypeOutputDto>;
    } & OkManyResponse)> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/{tenant}/{company}/erp/project-work-types/{id}',
            path: {
                'tenant': tenant,
                'company': company,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
