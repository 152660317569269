/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CollaboratorCertificationOutputDto } from './CollaboratorCertificationOutputDto';
import type { CollaboratorPositionOutputDto } from './CollaboratorPositionOutputDto';
import type { CollaboratorProfileOutputDto } from './CollaboratorProfileOutputDto';
import type { CollaboratorShortOutputDto } from './CollaboratorShortOutputDto';
import type { CollaboratorSkillValueShortOutputDto } from './CollaboratorSkillValueShortOutputDto';

export type CollaboratorOutputDto = {
    _created: string;
    _updated: string;
    id: number;
    uuid: string;
    name: string;
    firstName: string;
    lastName: string;
    privateEmail?: string;
    workEmail: string;
    collaboratorSkillValues: Array<CollaboratorSkillValueShortOutputDto>;
    legalType: string;
    BOZP: boolean;
    medicalCheck: boolean;
    phone?: string;
    birthdate?: string;
    permanentResidence?: string;
    education?: string;
    bankAccountNumber?: string;
    workStart: string;
    workEnd?: string;
    active: boolean;
    generatedId: string;
    salesNote: string;
    salesNoteUpdated?: string;
    insuranceCompany?: string;
    contactPersonPhone?: string;
    contactsAddress?: string;
    companyIdentificationNumber?: string;
    hrNote?: string;
    hrNoteUpdated?: string;
    responsiblePerson?: CollaboratorShortOutputDto;
    skillValuesUpdatedAt?: string;
    skillValuesUpdatedBy?: CollaboratorShortOutputDto;
    type: CollaboratorOutputDto.type;
    positions: Array<CollaboratorPositionOutputDto>;
    collaboratorProfile?: CollaboratorProfileOutputDto;
    certifications?: Array<CollaboratorCertificationOutputDto>;
    jiraId?: string;
    personalIdentificationNumber?: string;
};

export namespace CollaboratorOutputDto {

    export enum type {
        INTERNAL_EMPLOYEE = 'INTERNAL_EMPLOYEE',
        EXTERNAL_EMPLOYEE = 'EXTERNAL_EMPLOYEE',
    }


}

