import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
  // Note - locales were converted to js file, since vue-18n has problems
  // with JSON modules. I assume after upgrade to latest version
  // we can convert this back
  // https://github.com/kazupon/vue-i18n/issues/1493
  const locales = import.meta.glob('./locales/*.ts', { eager: true });

  const messages = {};
  for (const key in locales) {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      messages[locale] = locales[key].default;
    }
  }

  return messages;
}

const i18n = createI18n({
  allowComposition: true,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || 'en',
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

export default i18n;
