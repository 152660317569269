import i18n from '@/i18n';
import { router } from '@/router';
import store from '@/store/store';
import VueViewer from 'v-viewer';
import { App } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import vuetify from './vuetify';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function registerPlugins(app: App<any>): App<any> {
  /******************************************************************************
   * VUE APEX CHARTS, https://apexcharts.com/docs/vue-charts/
   *****************************************************************************/
  app.use(VueApexCharts);

  /******************************************************************************
   * VUE ROUTER
   *****************************************************************************/
  app.use(router);

  /******************************************************************************
   * V-viewer
   *****************************************************************************/
  app.use(VueViewer);

  /******************************************************************************
   * VUE APPLICATION
   *****************************************************************************/

  app.use(vuetify).use(i18n).use(store);
  return app;
}
