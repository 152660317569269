export const DATE_FORMAT_STRING = 'DD.MM.YYYY';
export const DATE_MONTH_FORMAT_STRING = 'MM. YYYY';
export const TIME_FORMAT_STRING = 'HH:mm';
export const DATETIME_FORMAT_STRING = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT_STRING_MONTH = 'YYYY-MM';
export const DEFAULT_TABLE_DEBOUNCE_TIME = 1000;
export const MAX_ITEMS_PER_PAGE = 999;
export const ITEMS_PER_PAGE_OPTIONS = [10, 20, 25, 30, 40, 50, 100, MAX_ITEMS_PER_PAGE];
export const VALIDATION_ERROR_CODE = 409;
export const DATA_CONFLICT_ERROR = 'Conflict';
export const EMAIL_IS_ALREADY_TAKEN_ERROR = 'EMAIL_IS_ALREADY_TAKEN';
export const PHONE_IS_ALREADY_TAKEN_ERROR = 'PHONE_IS_ALREADY_TAKEN';
export const JIRA_IS_ALREADY_TAKEN_ERROR = 'JIRA_IS_ALREADY_TAKEN';
export const INPUT_VALIDATION_ERROR = 'INPUT_VALIDATION_ERROR';
export const TFPL_ID_TOKEN = 'tfpl-id-token';
export const DEFAULT_DIALOG_WIDTH = 1000;
export const LEFT_MENU_RAIL_WIDTH = 95;
export const AG_GRID_CELL_MIN_SMALL_WIDTH = 90;
export const AG_GRID_CELL_MIN_WIDTH = 150;
export const AG_GRID_TEXT_CELL_MIN_WIDTH = 175;
export const UNAUTHORIZED_ERROR_CODE = 401;
export const DEFAULT_QUICK_SEARCH_DEBOUNCE_TIME = 200;
export const INTERNAL_EMPLOYEE = 'INTERNAL_EMPLOYEE';
export const EXTERNAL_EMPLOYEE = 'EXTERNAL_EMPLOYEE';
export const LEAVING_CHECKLIST_EXTERNAL = 'EXTERNAL';
export const LEAVING_CHECKLIST_INTERNAL = 'INTERNAL';
export const BAD_REQUEST = 400;
export const NO_UNIQUE_ORGANIZATION_POSITIONS = 'NO_UNIQUE_ORGANIZATION_POSITIONS';
export const HPP = 'HPP';
export const DPP = 'DPP';
export const ZL = 'ŽL';
export const MAX_DPP_WAGE_WITHOUT_VAT = 10000;
export const GROSS_WAGE_VAT = 1.35;
export const WORKING_DAYS_COUNT = 252;

export const INVOICE_STATUS_PAID = 'PAID';
export const INVOICE_STATUS_PENDING = 'PENDING';
export const INVOICE_STATUS_UNPAID = 'UNPAID';

export const INVOICE_TYPE_STANDARD = 'STANDARD_INVOICE';
export const INVOICE_TYPE_PROFORMA = 'PROFORMA_INVOICE';
