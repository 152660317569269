<template>
  <v-card v-if="mounted" width="100%" variant="tonal" data-cy="other-info">
    <v-row no-gutters>
      <v-card-title class="w-100 d-flex justify-space-between">
        {{ $t('collaboratorProfilesPage.other') }}
        <c-copy-button data-cy="collaborator-profile-copy-other-info" :get-data="getCopyData" />
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-row class="pb-3">
          <v-col lg="6" cols="12">
            <v-list-subheader class="text-body-1 pa-0 mb-2 subhead">{{
              $t('collaboratorProfilesPage.strengthsWeaknesses')
            }}</v-list-subheader>
            <c-text-editor :editor="swEditor" typing class="ml-1 mr-1" />
          </v-col>

          <v-col lg="6" cols="12">
            <v-list-subheader class="text-body-1 pa-0 mb-2 subhead">{{
              $t('collaboratorProfilesPage.otherTechnologies')
            }}</v-list-subheader>
            <c-text-editor :editor="otEditor" typing class="ml-1 mr-1" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import { onMounted, ref, toRefs, watch } from 'vue';
import CTextEditor from '@/components/Common/CTextEditor.vue';
import { Editor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Link from '@tiptap/extension-link';
import { CollaboratorProfileOutputDto } from 'generated-api/erp';
import { useCopyData } from '@/composables/useCopyData';
import CCopyButton from '@/components/Common/CCopyButton.vue';

const { getCollaboratorProfileOtherCopyData } = useCopyData();

const swEditor = ref();
const otEditor = ref();

const mounted = ref(false);

const props = defineProps<{
  collaboratorProfile: CollaboratorProfileOutputDto;
}>();

const emit = defineEmits(['updateProfile']);

const { collaboratorProfile } = toRefs(props);

const otherTechnologies = ref(collaboratorProfile.value.otherTechnologies || '');
const strengthsWeaknesses = ref(collaboratorProfile.value.strengthsWeaknesses || '');

const emitCollaboratorChange = () => {
  if (!collaboratorProfile.value) {
    collaboratorProfile.value = {
      otherTechnologies: otherTechnologies.value,
      strengthsWeaknesses: strengthsWeaknesses.value,
    } as CollaboratorProfileOutputDto;
  } else {
    collaboratorProfile.value.otherTechnologies = otherTechnologies.value;
    collaboratorProfile.value.strengthsWeaknesses = strengthsWeaknesses.value;
  }

  emit('updateProfile', collaboratorProfile.value);
};

const makeEditorInstances = () => {
  swEditor.value = new Editor({
    editable: true,
    content: strengthsWeaknesses.value,
    extensions: [
      StarterKit.configure({
        heading: false,
      }),
      Underline,
      Placeholder.configure({
        emptyEditorClass: 'is-editor-empty',
        emptyNodeClass: 'is-empty',
        showOnlyWhenEditable: true,
        showOnlyCurrent: true,
      }),
      TaskList,
      TaskItem,
      Link,
    ],
    onUpdate({ editor }) {
      strengthsWeaknesses.value = editor.getHTML() === '<p></p>' ? '' : editor.getHTML();
      emitCollaboratorChange();
    },
  });

  otEditor.value = new Editor({
    editable: true,
    content: otherTechnologies.value,
    extensions: [
      StarterKit.configure({
        heading: false,
      }),
      Underline,
      Placeholder.configure({
        emptyEditorClass: 'is-editor-empty',
        emptyNodeClass: 'is-empty',
        showOnlyWhenEditable: true,
        showOnlyCurrent: true,
      }),
      TaskList,
      TaskItem,
      Link,
    ],
    onUpdate({ editor }) {
      otherTechnologies.value = editor.getHTML() === '<p></p>' ? '' : editor.getHTML();
      emitCollaboratorChange();
    },
  });
};

const getCopyData = () => getCollaboratorProfileOtherCopyData(collaboratorProfile.value);

onMounted(() => {
  mounted.value = true;
  makeEditorInstances();
});

watch(
  () => props.collaboratorProfile,
  newProfile => {
    otherTechnologies.value = newProfile.otherTechnologies || '';
    strengthsWeaknesses.value = newProfile.strengthsWeaknesses || '';

    swEditor.value.commands.setContent(strengthsWeaknesses.value);
    otEditor.value.commands.setContent(otherTechnologies.value);
  },
  { deep: true },
);
</script>

<style lang="scss" scoped>
.subhead {
  margin-left: 4px;
}
</style>
