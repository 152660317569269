import { useLogger } from '@/composables/useLogger';
import type { UlaUser } from '@/types/ula';
import { UserProfileDto } from 'generated-api/agw';
import { computed, ref } from 'vue';
import { useEnums } from './api/useEnums';

interface Company {
  code: string;
  name: string;
  uuid: string;
}

const companies = ref<Company[]>([]);
const activeCompany = ref<Company>({ code: '', name: '', uuid: '' });

const getCompaniesFromUser = (user: UserProfileDto): Company[] => {
  return user.companyPermissions.map(company => {
    return {
      code: company.code,
      name: company.name,
      uuid: company.uuid,
    };
  });
};

export const useActiveCompany = () => {
  const { getEnums } = useEnums();
  const { logger } = useLogger();

  /**
   * Asynchronously sets the list of companies and updates the active company if needed.
   *
   * This function retrieves companies associated with the user as specified in the user profile DTO,
   * and assigns them to the `companies` variable. If there is no currently active company,
   * it sets the first company from the list as the active company. It also fetches enumerations
   * for the active company.
   *
   * @param {UserProfileDto} userProfileDto - The user profile data transfer object containing user information.
   * @returns {Promise<void>} - A promise that resolves when the company list and active company are updated.
   */
  const setCompanies = async (
    userProfileDto: UserProfileDto | UlaUser,
    selectedTenantCode: string | undefined,
  ): Promise<void> => {
    companies.value = getCompaniesFromUser(userProfileDto);

    if (!activeCompany.value.code && companies.value.length > 0) {
      const defaultCompany = companies.value[0];
      activeCompany.value = selectedTenantCode
        ? companies.value.find(c => c.code === selectedTenantCode) ?? defaultCompany
        : defaultCompany;
    }
    await getEnums(activeCompany.value.code);
  };

  /**
   * Asynchronously sets the active company based on the given company code.
   * Searches for the company in the available companies list.
   * If the company is found, it updates the active company and fetches related enums.
   * Logs an error if the company is not found.
   *
   * @param {string} companyCode - The code of the company to be set as active.
   * @return {Promise<void>} - A promise that resolves when the active company is set and enums are fetched.
   */
  const setActiveCompany = async (companyCode: string): Promise<void> => {
    const selectedCompany = companies.value.find(c => c.code === companyCode);
    if (selectedCompany) {
      activeCompany.value = selectedCompany;
      await getEnums(selectedCompany.code);
    } else {
      logger.error(`Company with code ${companyCode} not found.`);
    }
  };

  const getCompanies = computed(() => companies.value);
  const getActiveCompany = () => activeCompany.value;

  return {
    getCompanies,
    getActiveCompany,
    setCompanies,
    setActiveCompany,
  };
};
