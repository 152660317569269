import { useConfig } from '@/composables/useConfig';

export const useUla = () => {
  const { getConfig } = useConfig();
  const sendMessageToUla = (message: string) => {
    const iframe = document.querySelector('#ulaIframe') as HTMLIFrameElement | null;
    iframe?.contentWindow?.postMessage(message, getConfig().ULA_URL);
  };

  const loadUserInfo = () => {
    sendMessageToUla('getUserInfo');
  };

  const logout = () => {
    sendMessageToUla('logout');
  };

  return {
    loadUserInfo,
    logout,
  };
};
