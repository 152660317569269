import { useFormatters } from '@/composables/useFormatters';

// TODO consider replacing this legacy-ish filter mutation with
// smth different (utils functions/composable)

const {
  toNiceCzk,
  toFloat,
  toDecimal,
  toPercentage,
  toRoundedPercentage,
  toInteger,
  toStandardDate,
  toStandardDateTime,
  toStandardMonthDate,
} = useFormatters();

export const filters = {
  toFloat,
  toDecimal,
  toPercentage,
  toRoundedPercentage,
  toInteger,
  toNiceCZK: toNiceCzk,
  toStandardDate,
  toStandardDateTime,
  toStandardMonthDate,
};
