import intersection from 'lodash/intersection';
import { useAuthentication } from '@/composables/auth/useAuthentication';
import { useActiveCompany } from '@/composables/useActiveCompany';

export type PermissionsMap = Map<string, string[]>; // This assumes that the permissions are stored as a Map with the subject as key and an array of actions as value

interface PermissionCheckOptions {
  subject: string;
  action: string | string[];
}

export function useAuthorization() {
  const { user } = useAuthentication();
  const { getActiveCompany } = useActiveCompany();

  /**
   * Checks if a user has permission to perform a specific action on a subject within a company.
   *
   * @param {string} companyCode - The company code.
   * @param {Map<string, PermissionsMap> | undefined} currentPermissions - The current permissions, stored in a map.
   * @param {PermissionCheckOptions} options - The options for permission check, including subject and action.
   * @returns {boolean} - Returns true if the user has permission, false otherwise.
   */
  const hasPermission = (
    companyCode: string,
    currentPermissions: Map<string, PermissionsMap> | undefined,
    { subject, action }: PermissionCheckOptions,
  ): boolean => {
    if (!currentPermissions) {
      return false;
    }

    // check for company code
    const companyPermissions = typeof currentPermissions.get === 'function' && currentPermissions.get(companyCode);
    if (!companyPermissions) {
      return false;
    }

    // check if subject exists
    const permissionSubject = companyPermissions.get(subject);
    if (!permissionSubject) {
      return false;
    }

    const actions = Array.isArray(action) ? action : [action];

    // action exists
    return intersection(permissionSubject, actions).length > 0;
  };

  /**
   * Checks if the logged user has the specified permission.
   * @param {string} subject - The subject of the permission.
   * @param {string|string[]} action - The action(s) of the permission.
   * @returns {boolean} - true if the logged user has the specified permission, false otherwise.
   */
  const hasLoggedUserPermission = (subject: string, action: string | string[]): boolean => {
    const companyCode = getActiveCompany().code;
    return hasPermission(companyCode, user.value.mappedPermissions, { subject, action });
  };

  return {
    hasPermission,
    hasLoggedUserPermission,
  };
}
