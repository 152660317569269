export const categoryDisplayNames: Record<string, string> = {
  designAndArchitecture: 'Design and Architecture',
  development: 'Development',
  testingAndQA: 'Testing and QA',
  maintenance: 'Maintenance',
  collaborationAndCommunication: 'Collaboration and Communication',
  projectManagement: 'Project Management',
};

export const actionDisplayNames: Record<string, string> = {
  softwareArchitecture: 'Software Architecture',
  databaseArchitecture: 'Database Architecture',
  technologySelection: 'Technology Selection',
  technicalLeadership: 'Technical Leadership',
  frontend: 'Frontend',
  backend: 'Backend',
  database: 'Database',
  api: 'Api',
  unitTesting: 'Unit testing',
  e2eTesting: 'E2E testing',
  codeReview: 'Code-review',
  bugFixing: 'Bug Fixing',
  refactoring: 'Refactoring',
  support: 'Support',
  customerCommunication: 'Customer Communication',
  teamCollaboration: 'Team Collaboration',
  documentation: 'Documentation',
  estimationAndPlanning: 'Estimation and Planning',
  taskManagement: 'Task Management',
  monitoringAndReporting: 'Monitoring and Reporting',
};
