/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CollaboratorUpdateDto = {
    firstName: string;
    lastName: string;
    privateEmail?: string;
    workEmail: string;
    legalType: string;
    BOZP: boolean;
    medicalCheck: boolean;
    phone?: string;
    birthdate?: string;
    personalIdentificationNumber?: string;
    permanentResidence?: string;
    education?: string;
    bankAccountNumber?: string;
    workStart: string;
    workEnd: string;
    jiraId?: string;
    profileUrl?: string;
    salesNote?: string;
    insuranceCompany?: string;
    contactPersonPhone?: string;
    contactsAddress?: string;
    companyIdentificationNumber?: string;
    hrNote?: string;
    active: boolean;
    type: CollaboratorUpdateDto.type;
    positionIds: Array<number>;
    responsiblePersonUuid?: string;
    collaboratorProfileUuid?: string;
};

export namespace CollaboratorUpdateDto {

    export enum type {
        INTERNAL_EMPLOYEE = 'INTERNAL_EMPLOYEE',
        EXTERNAL_EMPLOYEE = 'EXTERNAL_EMPLOYEE',
    }


}

