import { MenuItemService } from 'generated-api/erp/services/MenuItemService';
import { SubmenuItemService } from 'generated-api/erp/services/SubmenuItemService';
import { routes } from '@/router';
import { useLogger } from '@/composables/useLogger';
import { useConfig } from '@/composables/useConfig';
import { useAuthentication } from '@/composables/auth/useAuthentication';
import { useAuthorization } from '@/composables/auth/useAuthorization';
import { useActiveCompany } from '@/composables/useActiveCompany';

export const GET_MENU_ITEMS = 'GET_MENU_ITEMS';
export const GET_MENU_ITEMS_START = 'GET_MENU_ITEMS_START';
export const GET_MENU_ITEMS_SUCCESS = 'GET_MENU_ITEMS_SUCCESS';
export const GET_MENU_ITEMS_ERROR = 'GET_MENU_ITEMS_ERROR';

export const GET_SUBMENU_ITEMS = 'GET_SUBMENU_ITEMS';
export const GET_SUBMENU_ITEMS_START = 'GET_SUBMENU_ITEMS_START';
export const GET_SUBMENU_ITEMS_SUCCESS = 'GET_SUBMENU_ITEMS_SUCCESS';
export const GET_SUBMENU_ITEMS_ERROR = 'GET_SUBMENU_ITEMS_ERROR';

export const FAVORITE_MENU_ITEM_ID = 0;

const { logger } = useLogger();
const { getConfig } = useConfig();
const { getActiveCompany } = useActiveCompany();

const state = {
  loading: false,
  menuItems: [],
  submenuItems: [],
};

const mutations = {
  [GET_MENU_ITEMS_START]: state => {
    state.loading = true;
  },
  [GET_MENU_ITEMS_SUCCESS]: (state, payload) => {
    state.loading = false;
    state.menuItems = payload.data;
  },
  [GET_MENU_ITEMS_ERROR]: state => {
    state.loading = false;
  },

  [GET_SUBMENU_ITEMS_START]: state => {
    state.loading = true;
  },
  [GET_SUBMENU_ITEMS_SUCCESS]: (state, payload) => {
    state.loading = false;
    state.submenuItems = payload.data;
  },
  [GET_SUBMENU_ITEMS_ERROR]: state => {
    state.loading = false;
  },
};

const actions = {
  [GET_MENU_ITEMS]: async ({ commit }) => {
    commit(GET_MENU_ITEMS_START);
    const { logout } = useAuthentication();
    try {
      const response = await MenuItemService.getAll({
        tenant: getConfig().TENANT,
        company: getActiveCompany().code,
      });
      commit(GET_MENU_ITEMS_SUCCESS, response);
    } catch (e) {
      commit(GET_MENU_ITEMS_ERROR);
      if ('response' in e && e.response.status === 401) {
        logout();
      }
      logger.error(e);
    }
  },
  [GET_SUBMENU_ITEMS]: async ({ commit, getters }) => {
    commit(GET_SUBMENU_ITEMS_START);
    const { logout } = useAuthentication();
    try {
      const response = await SubmenuItemService.getAll({
        tenant: getConfig().TENANT,
        company: getters.personalSettings.selectedTenantCode,
      });
      commit(GET_SUBMENU_ITEMS_SUCCESS, response);
    } catch (e) {
      commit(GET_SUBMENU_ITEMS_ERROR);
      if ('response' in e && e.response.status === 401) {
        logout();
      }
      logger.error(e);
    }
  },
};

const getters = {
  menuColor: state => {
    return menuItemName => {
      const menuItem = state.menuItems.find(i => i.name.toLowerCase() === menuItemName.toLowerCase());
      if (menuItem) {
        return menuItem.color;
      } else {
        return 'grey';
      }
    };
  },
  menuItemTree: (state, getters, rootState) => {
    const { user } = useAuthentication();
    const { hasPermission } = useAuthorization();

    const currentPermissions = user.value.mappedPermissions;
    const currentUserEmail = user.value.email;
    const currentUserPositions = user.value.permissions.flatMap(permission =>
      permission.positions.map(position => position.uuid),
    );
    const userFavorites = rootState.personalSettings.favoriteMenuItems;
    const favoriteMenuItem = {
      id: FAVORITE_MENU_ITEM_ID,
      order: 0,
      icon: 'mdi-star',
      name: 'Favorite',
      color: 'blue-grey',
      submenuItems: [],
    };

    // add static pages submenu items (from vue router)
    const appRouteNode = routes.find(node => node.path === '/app');

    // add submenu items (fetched from api) for documents
    const menuItems = state.menuItems.map(item => {
      const newItem = { ...item };
      newItem.submenuItems = state.submenuItems.filter(submenuItem => {
        if (submenuItem.menuItem.id === item.id) {
          for (const user of submenuItem.showToUser) {
            if (user.name === currentUserEmail) {
              return submenuItem;
            }
          }
          for (const position of submenuItem.showToPosition) {
            if (currentUserPositions.includes(position.uuid)) {
              return submenuItem;
            }
          }
        }
      });

      return newItem;
    });

    for (const routeNode of appRouteNode.children) {
      const matchingMenuItem = menuItems.find(item => item.name.toLowerCase() === routeNode.path.toLowerCase());
      if (matchingMenuItem) {
        routeNode.children.forEach(children => {
          if (typeof children.meta.permission !== 'object') return;
          if (hasPermission(getActiveCompany().code, currentPermissions, children.meta.permission)) {
            const staticSubmenuItem = {
              cySelector: `${routeNode.path}-${children.path}`,
              id: `${routeNode.path}-${children.path}`,
              name: children.name,
              link: `/app/${routeNode.path}/${children.path}`,
              type: 'static',
              key: children.meta.key,
            };

            matchingMenuItem.submenuItems.push(staticSubmenuItem);
          }
        });
      }
    }

    const allSubmenuItems = menuItems.flatMap(item => item.submenuItems).filter(Boolean);

    if (userFavorites) {
      userFavorites.forEach(favoriteId => {
        const favoriteSubmenutItem = allSubmenuItems.find(submenuItem => submenuItem.id.toString() === favoriteId);
        if (favoriteSubmenutItem) {
          favoriteMenuItem.submenuItems.push(favoriteSubmenutItem);
        }
      });
    }

    return [favoriteMenuItem, ...menuItems].filter(item => {
      if (item.submenuItems.length) {
        return item;
      }
    });
  },
};

export default {
  mutations,
  state,
  actions,
  getters,
};
