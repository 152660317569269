/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ProjectShortOutputDto } from './ProjectShortOutputDto';

export type ProjectPlanOutputDto = {
    _created: string;
    _updated: string;
    id: number;
    uuid: string;
    project: ProjectShortOutputDto;
    plannedCapacity: number;
    month: string;
    orderStatus: ProjectPlanOutputDto.orderStatus;
};

export namespace ProjectPlanOutputDto {

    export enum orderStatus {
        NO_ORDER = 'NoOrder',
        WAITING_FOR_SIGN = 'WaitingForSign',
        IN_PROGRESS = 'InProgress',
        DONE = 'Done',
    }


}

