<template>
  <v-card v-if="mounted" width="100%" variant="tonal" class="collaborator-certifications-wrapper">
    <v-row no-gutters>
      <v-card-title class="w-100 d-flex justify-space-between">
        {{ $t('collaboratorProfilesPage.certifications') }}
        <c-copy-button data-cy="collaborator-profile-copy-certificates" :get-data="getCopyData" />
      </v-card-title>
      <v-divider />

      <v-card-text>
        <div>
          <v-row>
            <v-col
              v-for="certification in certifications"
              :key="certification.uuid"
              cols="12"
              class="certification-col"
            >
              <v-checkbox
                v-model="selectedCertifications"
                :disabled="!hasLoggedUserPermission('ERP_CERTIFICATIONS', ['READ'])"
                :label="certification.name"
                density="compact"
                hide-details
                :value="certification.uuid"
                @update:model-value="emitCollaboratorChange"
              />

              <p>{{ certification.description }}</p>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import {
  CollaboratorCertificationOutputDto,
  CollaboratorCertificationsService,
  CollaboratorProfileOutputDto,
} from 'generated-api/erp';
import { useConfig } from '@/composables/useConfig';
import { useI18n } from 'vue-i18n';
import { useAuthorization } from '@/composables/auth/useAuthorization';
import { useLogger } from '@/composables/useLogger';
import { useSnack } from '@/composables/useSnack';
import { useActiveCompany } from '@/composables/useActiveCompany';
import { useCopyData } from '@/composables/useCopyData';
import CCopyButton from '@/components/Common/CCopyButton.vue';

const { getCollaboratorProfileCertificationsCopyData } = useCopyData();

const properties = defineProps<{
  collaboratorProfile: CollaboratorProfileOutputDto;
}>();

const { getConfig } = useConfig();
const { t } = useI18n();
const { hasLoggedUserPermission } = useAuthorization();
const { logger } = useLogger();
const { addSnack } = useSnack();
const { getActiveCompany } = useActiveCompany();

const mounted = ref(false);
const certifications = ref<CollaboratorCertificationOutputDto[]>([]);
const selectedCertifications = ref<string[]>([]);

const emit = defineEmits(['updateProfile']);

const emitCollaboratorChange = () => {
  const updatedCertifications = selectedCertifications.value.length
    ? certifications.value.filter(c => selectedCertifications.value.includes(c.uuid))
    : [];

  const updatedCollaborator = {
    ...properties.collaboratorProfile.collaborator,
    certifications: updatedCertifications,
  };

  const updatedCollaboratorProfile = {
    ...properties.collaboratorProfile,
    collaborator: updatedCollaborator,
  };

  emit('updateProfile', updatedCollaboratorProfile);
};

const fetchData = async () => {
  // Reset the certifications array
  certifications.value = [];

  try {
    const res = await CollaboratorCertificationsService.getAll({
      tenant: getConfig().TENANT,
      company: getActiveCompany().code,
    });

    certifications.value = res.data.filter(cert => {
      const depUuids = properties.collaboratorProfile.collaborator.positions.map(p => p.department.uuid);
      return depUuids.includes(cert.department.uuid) ? cert : false;
    });

    // selected certifications should be first
    certifications.value = certifications.value.sort(a => {
      if (properties.collaboratorProfile.collaborator.certifications?.map(c => c.uuid).includes(a.uuid)) {
        return -1;
      }
      return 1;
    });
  } catch (e) {
    logger.error(e);
    addSnack({
      id: 'toast.error',
      message: t('toast.error'),
    });
  }
};

const getCopyData = () => getCollaboratorProfileCertificationsCopyData(properties.collaboratorProfile);

defineExpose({
  fetchData,
});

onMounted(() => {
  fetchData();
  mounted.value = true;
});

watch(
  () => properties.collaboratorProfile.uuid,
  () => {
    fetchData();
  },
);

watch(
  () => properties.collaboratorProfile.collaborator.certifications,
  newCertifications => {
    if (newCertifications) {
      selectedCertifications.value = newCertifications.map(c => c.uuid);
    } else {
      selectedCertifications.value = [];
    }
  },
  { immediate: true },
);
</script>
