import { useConfig } from '@/composables/useConfig';
import { useLogger } from '@/composables/useLogger';
import { useSnack } from '@/composables/useSnack';
import { AxiosError } from 'axios';
import { EnumsService } from 'generated-api/erp';
import { computed, ref } from 'vue';

const userRoles = ref<string[]>([]);
const projectCategories = ref<string[]>([]);
const currencies = ref<string[]>([]);
const workLogIssueTypes = ref<string[]>([]);
const workLogIssueStatuses = ref<string[]>([]);
const legalTypes = ref<string[]>([]);
const orderConfirmTypes = ref<string[]>([]);

export const useEnums = () => {
  const { getConfig } = useConfig();
  const { logger } = useLogger();
  const { addSnack } = useSnack();

  /**
   * Handles and logs any errors that occur during the API call. Then show a snack notification with the error.
   *
   * @param {unknown} e - The error object that occurred during the API call.
   * @returns {void}
   */
  const catchError = (e: unknown): void => {
    logger.error(e);
    addSnack({
      id: (e as AxiosError).message,
      message: (e as AxiosError).message,
    });
  };

  /**
   * Fetch various enums for the active company and tenant, such as user roles, project categories, currencies, etc.
   * The retrieved data is saved into respective reactive variables.
   *
   * @param {string} companyCode - The code of the active company for which enums should be fetched.
   * @returns {Promise<void>} - Resolves when the enum data has been successfully fetched and saved.
   */
  const getEnums = async (companyCode: string): Promise<void> => {
    try {
      const response = await EnumsService.getAll({
        tenant: getConfig().TENANT,
        company: companyCode,
      });

      userRoles.value = response.data.userRoles ?? [];
      projectCategories.value = response.data.projectCategories ?? [];
      currencies.value = response.data.currencies ?? [];
      workLogIssueTypes.value = response.data.workLogIssueTypes ?? [];
      workLogIssueStatuses.value = response.data.workLogIssueStatuses ?? [];
      legalTypes.value = response.data.legalTypes ?? [];
      orderConfirmTypes.value = response.data.orderConfirmTypes ?? [];
    } catch (e) {
      catchError(e);
    }
  };

  const computedUserRoles = computed(() => userRoles.value);
  const computedProjectCategories = computed(() => projectCategories.value);
  const computedCurrencies = computed(() => currencies.value);
  const computedWorkLogIssueTypes = computed(() => workLogIssueTypes.value);
  const computedWorkLogIssueStatuses = computed(() => workLogIssueStatuses.value);
  const computedLegalTypes = computed(() => legalTypes.value);
  const computedOrderConfirmTypes = computed(() => orderConfirmTypes.value);

  return {
    getEnums,
    getUserRoles: computedUserRoles,
    getProjectCategories: computedProjectCategories,
    getCurrencies: computedCurrencies,
    getWorkLogIssueTypes: computedWorkLogIssueTypes,
    getWorkLogIssueStatuses: computedWorkLogIssueStatuses,
    getLegalTypes: computedLegalTypes,
    getOrderConfirmTypes: computedOrderConfirmTypes,
  };
};
