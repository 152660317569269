/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AggregateInvoiceOutputDto = {
    taxPointDate: string;
    priceWithoutVat: number;
    status: AggregateInvoiceOutputDto.status;
};

export namespace AggregateInvoiceOutputDto {

    export enum status {
        PAID = 'PAID',
        PENDING = 'PENDING',
        UNPAID = 'UNPAID',
    }


}

