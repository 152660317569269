<template>
  <div>
    <router-view />
    <iframe id="ulaIframe" :src="ulaURL" style="display: none"></iframe>
  </div>
</template>
<script lang="ts" setup>
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { UlaUserOrNull } from '@/types/ula';
import { useAuthentication } from '@/composables/auth/useAuthentication';
import { useConfig } from '@/composables/useConfig';
import { onMounted } from 'vue';
import { useWebSocket } from '@/composables/useWebSocket';
const { isLogged, initLoggedUser, logout, refreshLogin } = useAuthentication();
const { createSocket, getSocket } = useWebSocket();
const { setConfig, getConfig } = useConfig();
const socket = getSocket();

const ulaURL = getConfig().ULA_URL;
if (import.meta.env.MODE != 'test') {
  const registratorUpdateInterval = 10 * 1000;
  useRegisterSW({
    onRegisteredSW(swUrl, registration) {
      if (registration)
        setInterval(async () => {
          if (!(!registration.installing && navigator)) return;

          if ('connection' in navigator && !navigator.onLine) return;

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          });

          if (resp?.status === 200) {
            await registration.update();
          }
        }, registratorUpdateInterval);
    },
  });
}

onMounted(() => {
  window.onmessage = (e: MessageEvent<UlaUserOrNull>) => {
    // process ULA messages only and ignore messages containing 'type' attribute (e.g. { "type": "inject-script-disable" })
    if (e.origin === getConfig().ULA_URL && !(e.data && 'type' in e.data)) {
      if (e.data) {
        if (!getConfig().TENANT) {
          setConfig({
            ...getConfig(),
            TENANT: e.data.tenant,
          });
        }

        if (!socket) {
          createSocket();
        }

        if (!isLogged.value) {
          initLoggedUser(e.data);
        } else {
          refreshLogin(e.data);
        }
      } else {
        logout();
      }
    }
  };
});
</script>
