import { actionDisplayNames } from '@/components/CollaboratorProfilesComponents/enums';
import type { ProjectsDataEntry } from '@/components/CollaboratorProfilesComponents/types';
import { useMoney } from '@/composables/useMoney';
import { ProjectRevenue } from '@/types/projectCalculation';
import { t } from '@/utils/i18n';
import { CollaboratorProfileOutputDto, ProjectWithCapacityDto } from 'generated-api/erp';
import { flatten } from 'lodash';
import { useHtml } from './useHtml';

const strengthsWeaknessesTitle = 'Silné a slabé stránky';
const otherTechnologiesTitle = 'Ostatní technologie';
const skillsTitle = 'Dovednosti';
const certificationsTitle = 'Certifikace';
const projectsTitle = 'Projekty';
const plannedCapacityLabel = 'Odpracováno MD';
const monthsOnProjectLabel = 'Počet měsíců na projektu';
const competenceLabel = 'Kompetence na projektu';

export const useCopyData = () => {
  const { getMoneyFormat } = useMoney();
  const { htmlToPlainText } = useHtml();

  const getProjectRevenuesCopyData = (projectRevenuesData: ProjectRevenue[], skipWorkType = false) => ({
    html: getProjectRevenuesAsHtml(projectRevenuesData, skipWorkType),
    text: getProjectRevenuesAsText(projectRevenuesData, skipWorkType),
  });

  const getCollaboratorProfileCopyData = (
    collaboratorProfile: CollaboratorProfileOutputDto,
    projects: ProjectWithCapacityDto[],
    projectsData: ProjectsDataEntry,
  ) => ({
    html: getCollaboratorProfileAsHtml(collaboratorProfile, projects, projectsData),
    text: getCollaboratorProfileAsText(collaboratorProfile, projects, projectsData),
  });

  const getCollaboratorProfileBaseInfoCopyData = (collaboratorProfile: CollaboratorProfileOutputDto) => ({
    html: getCollaboratorProfileBaseInfoAsHtml(collaboratorProfile),
    text: getCollaboratorProfileBaseInfoAsText(collaboratorProfile),
  });

  const getCollaboratorProfileSkillsCopyData = (collaboratorProfile: CollaboratorProfileOutputDto) => ({
    html: getCollaboratorProfileSkillsAsHtml(collaboratorProfile),
    text: getCollaboratorProfileSkillsAsText(collaboratorProfile),
  });

  const getCollaboratorProfileCertificationsCopyData = (collaboratorProfile: CollaboratorProfileOutputDto) => ({
    html: getCollaboratorProfileCertificationsAsHtml(collaboratorProfile),
    text: getCollaboratorProfileCertificationsAsText(collaboratorProfile),
  });

  const getCollaboratorProfileOtherCopyData = (collaboratorProfile: CollaboratorProfileOutputDto) => ({
    html: getCollaboratorProfileOtherAsHtml(collaboratorProfile),
    text: getCollaboratorProfileOtherAsText(collaboratorProfile),
  });

  const getCollaboratorProfileProjectsCopyData = (
    projects: ProjectWithCapacityDto[],
    projectsData: ProjectsDataEntry,
  ) => ({
    html: getCollaboratorProfileProjectsAsHtml(projects, projectsData),
    text: getCollaboratorProfileProjectsAsText(projects, projectsData),
  });

  const getProjectRevenuesAsHtml = (projectRevenuesData: ProjectRevenue[], skipWorkType: boolean) => {
    let htmlTableContent =
      '<table width="650px" style="font-family: monospace; text-align: right; border-collapse: collapse; border: 1px solid silver;" border="1" cellpadding="5" cellspacing="0">';
    htmlTableContent += `<tr style="color: white; background: #3D85C6;text-align: center">`;

    if (!skipWorkType) {
      htmlTableContent += `<td width="20%"><strong>${t('projectCalculation.workType')}</strong><br>Typ práce</td>`;
    }

    htmlTableContent += `<td width="20%"><strong>${t('projectCalculation.description')}</strong><br>Popis</td>`;
    htmlTableContent += `<td><strong>${t('projectCalculation.pricePerHour')}</strong><br>Cena za hodinu</td>`;
    htmlTableContent += `<td><strong>${t('projectCalculation.workedTime')}</strong><br>Odprac. hodin</td>`;
    htmlTableContent += `<td><strong>${t('projectCalculation.price')}</strong><br>Cena bez DPH</td></tr>`;
    let totalPrice = 0;

    projectRevenuesData.forEach(item => {
      if (!skipWorkType) {
        htmlTableContent += `<tr><td style="text-align: left">${item.workType?.name ?? ''}</td>`;
      }
      htmlTableContent += `<td style="text-align: left">${item.description}</td>`;
      htmlTableContent += `<td>${getMoneyFormat(item.pricePerHour)} ${t('czk')}</td>`;
      htmlTableContent += `<td>${getMoneyFormat(item.workedTimeInHours, undefined, 2)}</td>`;
      const sumPrice = item.sumPrice ?? 0;
      htmlTableContent += `<td>${getMoneyFormat(sumPrice)} ${t('czk')}</td></tr>`;
      totalPrice += sumPrice;
    });

    let emptyColumn = '';
    if (!skipWorkType) {
      emptyColumn = '<td>&nbsp;</td>';
    }

    htmlTableContent += `<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td>${emptyColumn}</tr>`;
    htmlTableContent += `<tr><td style="text-align: left"><strong>${t(
      'projectCalculation.total',
    )}</strong>, Celkem</td><td>&nbsp;</td><td>&nbsp;</td>${emptyColumn}`;
    htmlTableContent += `<td><strong>${getMoneyFormat(totalPrice)} ${t('czk')}</strong></td></tr>`;
    htmlTableContent += '</table>';

    return htmlTableContent;
  };

  const getProjectRevenuesAsText = (projectRevenuesData: ProjectRevenue[], skipWorkType: boolean) => {
    let textTableContent = `${t('projectCalculation.workType')}\t${t('projectCalculation.description')}\t${t(
      'projectCalculation.pricePerHour',
    )}\t${t('projectCalculation.workedTime')}\t${t('projectCalculation.price')}\n`;
    textTableContent += `Typ práce\tPopis\t\tCena za hodinu\tOdprac. hodin\tCena\n\n`;
    let totalPrice = 0;

    projectRevenuesData.forEach(item => {
      let workType = '';
      if (!skipWorkType) {
        workType = item.workType?.name ?? '';
      }
      const sumPrice = item.sumPrice ?? 0;
      textTableContent += `${workType}\t${item.description}\t${getMoneyFormat(item.pricePerHour)} ${t(
        'czk',
      )}\t${getMoneyFormat(item.workedTimeInHours, undefined, 2)}\t${getMoneyFormat(sumPrice)} ${t('czk')}\n`;
      totalPrice += sumPrice;
    });

    textTableContent += `\n${t('projectCalculation.total')}, Celkem:\t\t\t${getMoneyFormat(totalPrice)} ${t('czk')}`;

    return textTableContent;
  };

  const getCollaboratorProfileAsHtml = (
    collaboratorProfile: CollaboratorProfileOutputDto,
    projects: ProjectWithCapacityDto[],
    projectsData: ProjectsDataEntry,
  ) => {
    let html =
      getCollaboratorProfileBaseInfoAsHtml(collaboratorProfile) +
      '<br />' +
      getCollaboratorProfileOtherAsHtml(collaboratorProfile) +
      getCollaboratorProfileSkillsAsHtml(collaboratorProfile) +
      getCollaboratorProfileCertificationsAsHtml(collaboratorProfile);

    const projectsHtml = getCollaboratorProfileProjectsAsHtml(projects, projectsData);

    if (projectsHtml.length > 0) {
      html += '<br /><hr /><br /><br />';
    }

    return html + projectsHtml;
  };

  const getCollaboratorProfileAsText = (
    collaboratorProfile: CollaboratorProfileOutputDto,
    projects: ProjectWithCapacityDto[],
    projectsData: ProjectsDataEntry,
  ) => {
    return [
      getCollaboratorProfileBaseInfoAsText(collaboratorProfile),
      getCollaboratorProfileOtherAsText(collaboratorProfile),
      getCollaboratorProfileSkillsAsText(collaboratorProfile),
      getCollaboratorProfileCertificationsAsText(collaboratorProfile),
      getCollaboratorProfileProjectsAsText(projects, projectsData),
    ]
      .filter(value => value)
      .join('\n\n');
  };

  const getCollaboratorProfileBaseInfoAsHtml = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    let html = `<h1 style="text-align: center; font-weight: normal; font-size: 32px; margin-bottom: 18px;">${getCollaboratorName(
      collaboratorProfile,
    )}</h1>`;
    html += `<div style="text-align: center; color: #666666; margin-bottom: 14px">${getCollaboratorPositions(
      collaboratorProfile,
    )}</div>`;
    html += '<ul>';
    html += `<li>${getCollaboratorYearsOfExperience(collaboratorProfile)}</li>`;
    html += `<li>${getCollaboratorWillingnessToLeadTeam(collaboratorProfile)}</li>`;
    html += `<li>${getCollaboratorExperienceInLeadingTeam(collaboratorProfile)}</li>`;
    html += '</ul>';
    return html;
  };

  const getCollaboratorProfileBaseInfoAsText = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    let text = `${getCollaboratorName(collaboratorProfile)}\n`;
    text += `${getCollaboratorPositions(collaboratorProfile)}\n\n`;
    text += `${getCollaboratorYearsOfExperience(collaboratorProfile)}\n`;
    text += `${getCollaboratorWillingnessToLeadTeam(collaboratorProfile)}\n`;
    text += getCollaboratorExperienceInLeadingTeam(collaboratorProfile);
    return text;
  };

  const getCollaboratorProfileSkillsAsHtml = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    const skills = getCollaboratorSkills(collaboratorProfile);

    if (!skills.length) {
      return '';
    }

    let html = title(skillsTitle);
    html += '<ul><li>';
    html += getCollaboratorSkills(collaboratorProfile).join('</li><li>');
    html += '</li></ul>';
    return html;
  };

  const getCollaboratorProfileSkillsAsText = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    let text = `${skillsTitle}\n\n`;
    text += `- ${getCollaboratorSkills(collaboratorProfile).join('\n- ')}`;
    return text;
  };

  const getCollaboratorProfileCertificationsAsHtml = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    const certifications = getCollaboratorCertificationsAsHtml(collaboratorProfile);
    if (!certifications.length) {
      return '';
    }

    let html = title(certificationsTitle);
    html += '<ul><li>';
    html += certifications.join('</li><li>');
    html += '</li></ul>';
    return html;
  };

  const getCollaboratorProfileCertificationsAsText = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    const certifications = getCollaboratorCertificationsAsText(collaboratorProfile);
    if (!certifications.length) {
      return '';
    }

    let text = `${certificationsTitle}\n\n`;
    text += `- ${certifications.join('\n- ')}`;
    return text;
  };

  const getCollaboratorProfileOtherAsHtml = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    let html = title(strengthsWeaknessesTitle);
    html += collaboratorProfile.strengthsWeaknesses;
    html += title(otherTechnologiesTitle);
    html += collaboratorProfile.otherTechnologies;
    return html;
  };

  const getCollaboratorProfileOtherAsText = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    let text = `${strengthsWeaknessesTitle}\n\n`;
    text += `${htmlToPlainText(collaboratorProfile.strengthsWeaknesses)}\n\n`;
    text += `${otherTechnologiesTitle}\n\n`;
    text += htmlToPlainText(collaboratorProfile.otherTechnologies);
    return text;
  };

  const getCollaboratorProfileProjectsAsHtml = (
    projects: ProjectWithCapacityDto[],
    projectsData: ProjectsDataEntry,
  ) => {
    if (!projects.length) {
      return '';
    }

    let html = title(projectsTitle);
    for (const project of getCollaboratorProjects(projects, projectsData)) {
      html += subtitle(project.name);
      html += `<div>${project.projectMainGoal}</div>`;
      html += '<br />';
      html += `<div><strong>${plannedCapacityLabel}:</strong> ${project.plannedCapacity}</div>`;
      html += `<div><strong>${monthsOnProjectLabel}:</strong> ${project.monthsOnProject}</div>`;
      html += '<br />';
      html += `<div>${competenceLabel}:</div>`;
      if (project.data.length > 0) {
        html += '<ul style="margin-top: 4px;"><li>';
        html += project.data.join('</li><li>');
        html += '</li></ul>';
      } else {
        html += `<div>-</div>`;
      }
    }
    return html;
  };

  const getCollaboratorProfileProjectsAsText = (
    projects: ProjectWithCapacityDto[],
    projectsData: ProjectsDataEntry,
  ) => {
    if (!projects.length) {
      return '';
    }

    let text = `${projectsTitle}\n\n`;
    for (const project of getCollaboratorProjects(projects, projectsData)) {
      text += `${project.name}\n`;
      text += `${project.projectMainGoal}\n`;
      text += '\n';
      text += `${plannedCapacityLabel}: ${project.plannedCapacity}\n`;
      text += `${monthsOnProjectLabel}: ${project.monthsOnProject}\n`;
      text += '\n';
      text += `${competenceLabel}:\n`;
      if (project.data.length > 0) {
        text += `- ${project.data.join('\n- ')}`;
      } else {
        text += '- \n';
      }

      text += '\n\n';
    }
    return text;
  };

  const title = (text: string) => `<h2 style="font-weight: normal; font-size: 22px;">${text}</h2>`;

  const subtitle = (text: string) => `<h3 style="font-weight: normal; font-size: 18px; color: #666666;">${text}</h3>`;

  const booleanToText = (value: boolean) => (value ? '✅' : '❌');

  const getCollaboratorName = (collaboratorProfile: CollaboratorProfileOutputDto) =>
    `${collaboratorProfile.collaborator.firstName} ${collaboratorProfile.collaborator.lastName}`;

  const getCollaboratorPositions = (collaboratorProfile: CollaboratorProfileOutputDto) =>
    `${collaboratorProfile.collaborator.positions
      .map(position => {
        switch (position.name) {
          case 'ANALYST':
            return 'Analytik';
          case 'DEVELOPER':
          case 'OUTSOURCE_DEVELOPER':
            return 'Vývojář';
          case 'JUNIOR_DEVELOPER':
            return 'Junior vývojář';
          case 'MEDIOR_DEVELOPER':
            return 'Medior vývojář';
          case 'SENIOR_DEVELOPER':
            return 'Senior vývojář';
          case 'QA_ENGINEER':
            return 'QA inženýr';
          case 'TECHLEAD':
            return 'TechLead';
          case 'TEAMLEAD':
            return 'TeamLead';
          default:
            return position.name;
        }
      })
      .join(', ')}`;

  const getCollaboratorYearsOfExperience = (collaboratorProfile: CollaboratorProfileOutputDto) =>
    `Počet let praxe: ${collaboratorProfile.yearsOfExperience}`;

  const getCollaboratorWillingnessToLeadTeam = (collaboratorProfile: CollaboratorProfileOutputDto) =>
    `Ochota vést tým: ${booleanToText(collaboratorProfile.willingnessToLeadTeam)}`;

  const getCollaboratorExperienceInLeadingTeam = (collaboratorProfile: CollaboratorProfileOutputDto) =>
    `Zkušenost s vedením týmu: ${booleanToText(collaboratorProfile.experienceInLeadingTeam)}`;

  const getCollaboratorSkills = (collaboratorProfile: CollaboratorProfileOutputDto) => {
    const skillValues = collaboratorProfile.collaborator.collaboratorSkillValues.sort((a, b) => {
      return a.value < b.value ? -1 : 1;
    });

    return skillValues.map(skill => {
      let text = `${skill.collaboratorSkill.name} - `;
      switch (skill.value) {
        case 1:
          text += 'senior';
          break;
        case 2:
        case 3:
          text += 'medior';
          break;
        default:
          text += 'junior';
          break;
      }
      return text;
    });
  };

  const getCollaboratorCertificationsAsHtml = (collaboratorProfile: CollaboratorProfileOutputDto) =>
    (collaboratorProfile.collaborator.certifications ?? []).map(
      certification => `<strong>${certification.name}</strong> - ${certification.description}`,
    );

  const getCollaboratorCertificationsAsText = (collaboratorProfile: CollaboratorProfileOutputDto) =>
    (collaboratorProfile.collaborator.certifications ?? []).map(
      certification => `${certification.name} - ${certification.description}`,
    );

  const getCollaboratorProjects = (projects: ProjectWithCapacityDto[], projectsData: ProjectsDataEntry) =>
    projects.map(project => ({
      ...project,
      name: `${project.project.abbreviation}: ${project.project.name}`,
      projectMainGoal: project.project.projectMainGoal ?? '❌ Popis projektu není vyplněný v ERP',
      data: flatten(projectsData[project.project.id].categories.map(category => category.items))
        .filter(item => item.active)
        .map(item => actionDisplayNames[item.name]),
    }));

  return {
    getProjectRevenuesCopyData,
    getCollaboratorProfileCopyData,
    getCollaboratorProfileBaseInfoCopyData,
    getCollaboratorProfileSkillsCopyData,
    getCollaboratorProfileCertificationsCopyData,
    getCollaboratorProfileOtherCopyData,
    getCollaboratorProfileProjectsCopyData,
  };
};
