export type ApplicationConfig = {
  SERVER_URL: string;
  REDIRECT_URL: string;
  TENANT: string; // Tenant is NOT set by env
  WEBSOCKET_URL: string;
  ULA_URL: string;
};

let config: ApplicationConfig = {
  SERVER_URL: '',
  TENANT: '',
  WEBSOCKET_URL: '',
  REDIRECT_URL: '',
  ULA_URL: '',
};

export const useConfig = () => {
  /**
   * Retrieves the application configuration.
   *
   * @returns {ApplicationConfig} The application configuration object.
   */
  const getConfig = (): ApplicationConfig => config;

  /**
   * Sets the configuration object for the application.
   *
   * @param {ApplicationConfig} newConfig - The new configuration object to be merged with the existing useConfig object.
   * @returns {void}
   */
  const setConfig = (newConfig: ApplicationConfig): void => {
    config = { ...config, ...newConfig };
  };

  return {
    getConfig,
    setConfig,
  };
};
