export const useMoney = () => {
  const getCompactMoneyFormat = (amount: number, currency?: string): string => {
    const absAmount = Math.abs(amount);
    const formattedAmount: { amount: number | string; suffix: string } = { amount: 0, suffix: '' };

    if (absAmount < 100 * 1000) {
      formattedAmount.amount = amount;
    } else if (absAmount < 1000 * 1000) {
      formattedAmount.amount = (amount / 1000).toFixed(1);
      formattedAmount.suffix = 'k';
    } else {
      formattedAmount.amount = (amount / 1000000).toFixed(1);
      formattedAmount.suffix = 'M';
    }

    const formatter = new Intl.NumberFormat('cs', { style: 'decimal' });
    const value = (formatter.format(Number(formattedAmount.amount)) + formattedAmount.suffix).replace(/\xA0/g, ' ');

    return currency ? `${value} ${currency}` : value;
  };

  const getMoneyFormat = (amount: number, currency?: string, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    if (!amount) {
      return '-';
    }
    const formatter = new Intl.NumberFormat('cs', { style: 'decimal', minimumFractionDigits, maximumFractionDigits });
    const value = formatter.format(amount);

    return currency ? `${value} ${currency}` : value;
  };

  const roundPrice = (number: number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  };

  return {
    getCompactMoneyFormat,
    getMoneyFormat,
    roundPrice,
  };
};
