import i18n from '../i18n';

export class KeyGroupI18n {
  constructor(keyGroup, options = { ignoreNonExisting: false }) {
    this.keyGroup = keyGroup;
    this.options = options;
  }

  t = (key, value = {}) => {
    const composedKey = this.getComposedKey(key);
    if (!te(composedKey)) {
      if (this.options.ignoreNonExisting) {
        return te(key) ? t(key, value) : '';
      } else {
        // translate the key without its group
        return t(key, value);
      }
    }
    return t(composedKey, value);
  };
  te = key => {
    const composedKey = this.getComposedKey(key);
    return te(composedKey) || te(key);
  };
  getComposedKey = key => {
    return this.keyGroup ? `${this.keyGroup}.${key}` : key;
  };
}

export const t = (key, value = {}) => {
  return i18n.global.t(key, value);
};

export const te = key => {
  return i18n.global.te(key);
};
