/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollaboratorCertificationCreateDto } from '../models/CollaboratorCertificationCreateDto';
import type { CollaboratorCertificationOutputDto } from '../models/CollaboratorCertificationOutputDto';
import type { CollaboratorCertificationUpdateDto } from '../models/CollaboratorCertificationUpdateDto';
import type { OkManyResponse } from '../models/OkManyResponse';
import type { OkOneResponse } from '../models/OkOneResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CollaboratorCertificationsService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static getAll({
        tenant,
        company,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
    }): CancelablePromise<({
        data: Array<CollaboratorCertificationOutputDto>;
    } & OkManyResponse)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{tenant}/{company}/erp/collaborator-certifications',
            path: {
                'tenant': tenant,
                'company': company,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static post({
        tenant,
        company,
        requestBody,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        requestBody: CollaboratorCertificationCreateDto,
    }): CancelablePromise<({
        data: CollaboratorCertificationOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{tenant}/{company}/erp/collaborator-certifications',
            path: {
                'tenant': tenant,
                'company': company,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static getOne({
        tenant,
        company,
        uuid,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        uuid: string,
    }): CancelablePromise<({
        data: CollaboratorCertificationOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{tenant}/{company}/erp/collaborator-certifications/{uuid}',
            path: {
                'tenant': tenant,
                'company': company,
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static put({
        tenant,
        company,
        uuid,
        requestBody,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        uuid: string,
        requestBody: CollaboratorCertificationUpdateDto,
    }): CancelablePromise<({
        data: CollaboratorCertificationOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/{tenant}/{company}/erp/collaborator-certifications/{uuid}',
            path: {
                'tenant': tenant,
                'company': company,
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static remove({
        tenant,
        company,
        uuid,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
        uuid: string,
    }): CancelablePromise<({
        data: CollaboratorCertificationOutputDto;
    } & OkOneResponse)> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/{tenant}/{company}/erp/collaborator-certifications/{uuid}',
            path: {
                'tenant': tenant,
                'company': company,
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns string
     * @throws ApiError
     */
    public static postCreateUpdateMessages({
        tenant,
        company,
    }: {
        /**
         * Code of TFPL tenant used for authentication, use "public" for skip authentication
         */
        tenant: string,
        /**
         * Code of TFPL tenant used for selecting data from destination service
         */
        company: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/{tenant}/{company}/erp/collaborator-certifications/create-updated-messages',
            path: {
                'tenant': tenant,
                'company': company,
            },
        });
    }

}
