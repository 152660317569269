<template>
  <v-card width="100%" class="fill-height pa-4 main-card">
    <v-row v-if="loading">
      <v-col lg="6">
        <v-skeleton-loader v-for="skeletonIndex in 3" :key="skeletonIndex" type="card" />
      </v-col>
      <v-col lg="6">
        <v-skeleton-loader v-for="skeletonIndex in 6" :key="skeletonIndex" type="paragraph" />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col lg="4" cols="12">
        <v-card variant="flat">
          <v-row no-gutters class="pt-2">
            <v-col cols="12">
              <div class="d-flex">
                <collaborator-selector
                  :collaborator-profiles="collaboratorProfiles"
                  :selected-collaborator-uuid="selectedCollaboratorUuid"
                  @select-profile="selectCollaboratorProfile"
                />
                <div class="pt-2 pl-4">
                  <c-copy-button
                    v-if="selectedCollaboratorUuid"
                    data-cy="collaborator-profile-copy"
                    :get-data="getAllCopyData"
                  />
                </div>
              </div>
            </v-col>
          </v-row>

          <template v-if="selectedCollaboratorProfile">
            <SaveWrapper
              v-if="isMobileDevice"
              :save-in-progress="saveInProgress"
              :is-same="isSame"
              :save-collaborator-profile="saveCollaboratorProfile"
              :selected-collaborator-profile="selectedCollaboratorProfile"
              :updated-date="updatedDate"
              :t="t"
              :has-logged-user-permission="hasLoggedUserPermission"
            />

            <v-row>
              <v-col cols="12">
                <collaborator-base-info
                  :collaborator-profile="selectedCollaboratorProfile"
                  @update-profile="updateCollaboratorProfile"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card width="100%" variant="tonal" data-cy="skills">
                  <v-row no-gutters>
                    <v-card-title class="w-100 d-flex justify-space-between">
                      {{ $t('collaboratorProfilesPage.skills') }}
                      <c-copy-button data-cy="collaborator-profile-copy-skills" :get-data="getSkillsCopyData" />
                    </v-card-title>
                    <v-divider />

                    <v-card-text>
                      <v-row>
                        <collaborator-skills
                          :collaborator-skills="selectedCollaboratorProfile.collaborator.collaboratorSkillValues"
                        />
                      </v-row>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <collaborator-certifications
                  ref="collaboratorCertificationsRef"
                  :collaborator-profile="selectedCollaboratorProfile"
                  @update-profile="updateCollaboratorProfile"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card width="100%" variant="tonal" data-cy="profile-url">
                  <v-row no-gutters>
                    <v-card-title class="justify-center">
                      {{ $t('collaboratorProfilesPage.profileUrl') }}
                    </v-card-title>
                    <v-divider />

                    <v-card-text>
                      <v-row>
                        <v-text-field
                          v-model="selectedCollaboratorProfileUrl"
                          density="compact"
                          data-cy="profileUrlTextField"
                        />
                      </v-row>
                      <v-row>
                        <v-btn
                          v-t="'buttons.openInNewWindow'"
                          size="small"
                          variant="outlined"
                          class="my-2"
                          @click="openProfileUrlInNewWindow"
                        />
                      </v-row>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </v-col>

      <v-col lg="8" cols="12">
        <v-card v-if="selectedCollaboratorProfile" variant="flat">
          <SaveWrapper
            v-if="!isMobileDevice"
            :save-in-progress="saveInProgress"
            :is-same="isSame"
            :save-collaborator-profile="saveCollaboratorProfile"
            :selected-collaborator-profile="selectedCollaboratorProfile"
            :updated-date="updatedDate"
            :has-logged-user-permission="hasLoggedUserPermission"
            class="pt-2"
          />

          <v-row class="other-info-row">
            <v-col cols="12">
              <collaborator-other-info
                :collaborator-profile="selectedCollaboratorProfile"
                @update-profile="updateCollaboratorProfile"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <collaborator-projects
                ref="collaboratorProjectsRef"
                :collaborator-profile="selectedCollaboratorProfile"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col v-if="!selectedCollaboratorProfile" cols="12">
        <h3 class="text-center">{{ $t('collaboratorProfilesPage.selectFirst') }}</h3>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { useCollaboratorProfiles } from '@/composables/api/useCollaboratorProfiles';
import CollaboratorSelector from '@/components/CollaboratorProfilesComponents/CollaboratorSelector.vue';
import CollaboratorBaseInfo from '@/components/CollaboratorProfilesComponents/CollaboratorBaseInfo.vue';
import CollaboratorOtherInfo from '@/components/CollaboratorProfilesComponents/CollaboratorOtherInfo.vue';
import CollaboratorProjects from '@/components/CollaboratorProfilesComponents/CollaboratorProjects.vue';
import {
  CollaboratorProfileCreateUpdateDto,
  CollaboratorProfileOutputDto,
  CollaboratorProfilesService,
} from 'generated-api/erp';
import { useConfig } from '@/composables/useConfig';
import _ from 'lodash';
import { useSnack } from '@/composables/useSnack';
import { useI18n } from 'vue-i18n';
import { useLogger } from '@/composables/useLogger';
import moment from 'moment/moment';
import { useDisplay } from '@/composables/useDisplay';
import { VBtn, VCol, VIcon, VRow } from 'vuetify/components';
import { useAuthorization } from '@/composables/auth/useAuthorization';
import CollaboratorSkills from '@/components/CollaboratorComponents/CollaboratorSkills.vue';
import CollaboratorCertifications from '@/components/CollaboratorProfilesComponents/CollaboratorCertifications.vue';
import { useRoute, useRouter } from 'vue-router';
import { useActiveCompany } from '@/composables/useActiveCompany';
import { useCopyData } from '@/composables/useCopyData';
import CCopyButton from '@/components/Common/CCopyButton.vue';

const SaveWrapper = {
  components: {
    VBtn,
    VRow,
    VCol,
    VIcon,
  },
  template: `
    <v-row class="save-wrapper">
      <v-col cols="12" class="fill-height">
        <div class="d-flex align-center justify-end ga-1 fill-height" data-cy="save-metadata">
          {{ $t('collaboratorProfilesPage.lastEdit') }} {{ updatedDate }}
          <template v-if="selectedCollaboratorProfile.updatedBy"
          >{{ $t('collaboratorProfilesPage.by') }}
            {{ selectedCollaboratorProfile.updatedBy.fullName }}
          </template
          >

          <v-btn
            data-cy="save-button"
            :disabled="isSame || (!hasLoggedUserPermission('ERP_COLLABORATOR_PROFILES', ['UPDATE_ALL', 'UPDATE_OWN']))"
            :loading="saveInProgress"
            variant="flat"
            color="primary"
            @click="saveCollaboratorProfile"
          >
            {{ $t('collaboratorProfilesPage.save') }}
          </v-btn>

          <v-icon v-if="!isSame" color="warning-lighten-1">mdi-alert</v-icon>
        </div>
      </v-col>
    </v-row>
  `,
  props: [
    'isSame',
    'saveInProgress',
    'saveCollaboratorProfile',
    'updatedDate',
    'selectedCollaboratorProfile',
    't',
    'hasLoggedUserPermission',
  ],
};

const { loading, fetchCollaboratorsAndProjects, collaboratorProfiles } = useCollaboratorProfiles();
const { getConfig } = useConfig();
const { addSnack } = useSnack();
const { t } = useI18n();
const { logger } = useLogger();
const { isMobile } = useDisplay();
const { hasLoggedUserPermission } = useAuthorization();
const route = useRoute();
const router = useRouter();
const { getActiveCompany } = useActiveCompany();
const { getCollaboratorProfileCopyData, getCollaboratorProfileSkillsCopyData } = useCopyData();

const isMobileDevice = computed(() => isMobile.value);

const selectedCollaboratorProfile = ref<CollaboratorProfileOutputDto | null>(null);
const originalCollaboratorProfile = ref<CollaboratorProfileOutputDto | null>(null);
const saveInProgress = ref<boolean>(false);
const collaboratorProjectsRef = ref<InstanceType<typeof CollaboratorProjects> | null>(null);
const collaboratorCertificationsRef = ref<InstanceType<typeof CollaboratorCertifications> | null>(null);

// Define the selected collaborator UUID
const selectedCollaboratorUuid = ref<string | null>(null);

const selectedCollaboratorProfileUrl = computed({
  get() {
    return selectedCollaboratorProfile.value?.profileUrl || '';
  },
  set(value) {
    selectedCollaboratorProfile.value.profileUrl = value === '' ? null : value;
  },
});

const selectCollaboratorProfile = async (collaboratorProfile: CollaboratorProfileOutputDto) => {
  selectedCollaboratorProfile.value = collaboratorProfile;
  originalCollaboratorProfile.value = _.cloneDeep(selectedCollaboratorProfile.value);
  selectedCollaboratorUuid.value = collaboratorProfile.uuid;
  await router.push({ path: 'collaborator-profiles', query: { uuid: selectedCollaboratorUuid.value } });
};

watch(
  () => route.query.uuid,
  () => {
    setSelectedUserBasedOnRoute();
  },
);

const updateCollaboratorProfile = async (collaboratorProfile: CollaboratorProfileOutputDto) => {
  selectedCollaboratorProfile.value = collaboratorProfile;
};

const isSame = computed(() => {
  return (
    _.isEqual(selectedCollaboratorProfile.value, originalCollaboratorProfile.value) &&
    collaboratorProjectsRef.value?.isUpdated === false
  );
});

const updatedDate = computed(() => {
  if (!selectedCollaboratorProfile.value?.updatedAt) {
    return '-';
  }

  return moment(selectedCollaboratorProfile.value?.updatedAt).format('DD.MM.YYYY');
});

const saveCollaboratorProfile = async () => {
  if (isSame.value) {
    return;
  }

  saveInProgress.value = true;
  const collaboratorCertUuids = selectedCollaboratorProfile?.value?.collaborator?.certifications?.map(
    cert => cert.uuid,
  );

  const payload = { ...(selectedCollaboratorProfile.value as CollaboratorProfileCreateUpdateDto) };

  if (payload.collaborator) {
    payload.collaborator.certificationsUuids = collaboratorCertUuids;
  }

  try {
    await collaboratorProjectsRef.value?.saveData();

    if (!originalCollaboratorProfile.value?._created) {
      const result = await CollaboratorProfilesService.post({
        tenant: getConfig().TENANT,
        company: getActiveCompany().code,
        requestBody: {
          ...payload,
          collaboratorId: selectedCollaboratorProfile.value?.collaborator.id as number,
        },
      });
      selectedCollaboratorProfile.value.updatedAt = result.data.updatedAt;
      selectedCollaboratorProfile.value.updatedBy = result.data.updatedBy;
    } else {
      const result = await CollaboratorProfilesService.put({
        tenant: getConfig().TENANT,
        company: getActiveCompany().code,
        uuid: selectedCollaboratorProfile.value?.uuid as string,
        requestBody: {
          ...payload,
          collaboratorId: selectedCollaboratorProfile.value?.collaborator.id as number,
        },
      });
      selectedCollaboratorProfile.value.updatedAt = result.data.updatedAt;
      selectedCollaboratorProfile.value.updatedBy = result.data.updatedBy;
    }

    await collaboratorCertificationsRef.value?.fetchData();
    originalCollaboratorProfile.value = _.cloneDeep(selectedCollaboratorProfile.value);

    addSnack({
      id: 'toast.success',
      message: t('toast.success'),
      color: 'success',
    });
  } catch (e) {
    logger.error(e);
    addSnack({
      id: 'toast.error',
      message: t('toast.error'),
    });
  } finally {
    saveInProgress.value = false;
  }
};

const setSelectedUserBasedOnRoute = () => {
  if (!route || !route.query || !route.query.uuid) {
    return;
  }

  const matchedCollaboratorProfile = collaboratorProfiles.value.find(profile => profile.uuid === route.query.uuid);
  if (matchedCollaboratorProfile) {
    selectCollaboratorProfile(matchedCollaboratorProfile);
  }
};

const openProfileUrlInNewWindow = () => {
  if (selectedCollaboratorProfileUrl.value && selectedCollaboratorProfileUrl.value !== '') {
    window.open(selectedCollaboratorProfileUrl.value, '_blank');
  }
};

const getAllCopyData = () =>
  getCollaboratorProfileCopyData(
    selectedCollaboratorProfile.value,
    collaboratorProjectsRef.value?.projects ?? [],
    collaboratorProjectsRef.value?.projectsData ?? {},
  );

const getSkillsCopyData = () => getCollaboratorProfileSkillsCopyData(selectedCollaboratorProfile.value);

onMounted(() => {
  fetchCollaboratorsAndProjects();
  watchEffect(() => {
    if (collaboratorProfiles.value.length > 0 && !selectedCollaboratorProfile.value) {
      setSelectedUserBasedOnRoute();
    }
  });
});
</script>

<style lang="scss" scoped>
::v-deep(.ga-1) {
  gap: 1rem;
}

::v-deep(.save-wrapper) {
  height: 78px;
}

.other-info-row {
  margin-top: 35px;
}
</style>
