import { computed, ref } from 'vue';
import type { Color } from '@/types/common';

export type Snack = {
  id: string;
  message: string;
  color?: Color;
  timeout?: number; // miliseconds
  count?: number;
};

const defaultSnackValue = {
  color: 'error' as Color,
  timeout: 3000,
};

const snacks = ref<Snack[]>([]);

export function useSnack() {
  /**
   * Adds a snack to the snacks list. If the snack already exists, it increments the count.
   *
   * @param {Snack} payload - The snack object to add, which contains information about the snack.
   */
  const addSnack = (payload: Snack) => {
    const existingSnack = snacks.value.find(snack => snack.id === payload.id);
    if (!existingSnack) {
      snacks.value.unshift({ ...defaultSnackValue, ...payload, count: 1 });
    } else {
      existingSnack.count ? (existingSnack.count += 1) : 0;
    }
  };

  /**
   * Removes a snack from the snacks array by its unique identifier.
   *
   * @param {string} id - The unique identifier of the snack to be removed.
   */
  const removeSnack = (id: string) => {
    const index = snacks.value.findIndex(snack => snack.id === id);
    if (index !== -1) {
      snacks.value.splice(index, 1);
    }
  };

  const getSnacks = computed(() => snacks.value);

  return {
    snacks: getSnacks,
    addSnack,
    removeSnack,
  };
}
