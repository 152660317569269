/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InvoiceCreateDto = {
    invoiceNumber: string;
    taxPointDate: string;
    dateCreated: string;
    dateOfPayment: string;
    variableSymbol: string;
    datePaid?: string;
    priceWithoutVat: number;
    expectedAmount: number;
    projectId: number;
    currency: string;
    vat: number;
    note?: string;
    orderId: number;
    type: InvoiceCreateDto.type;
    proformaInvoiceIds?: Array<number>;
};

export namespace InvoiceCreateDto {

    export enum type {
        PROFORMA_INVOICE = 'PROFORMA_INVOICE',
        STANDARD_INVOICE = 'STANDARD_INVOICE',
    }


}

