import { createApp } from 'vue';
import axios from 'axios';
import 'viewerjs/dist/viewer.css';

import { filters } from './filters';
import { ApplicationConfig, useConfig } from './composables/useConfig';
import App from './App.vue';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { LicenseManager } from 'ag-grid-enterprise';
import { registerPlugins } from './plugins';
import { useLogger } from '@/composables/useLogger';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-054932}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TechFides_Solutions_s.r.o.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TF-ERP}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{TF-ERP}_need_to_be_licensed___{TF-ERP}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_April_2025}____[v3]_[01]_MTc0NDU4NTIwMDAwMA==83fdf32a46a79e843dd556bc2525c921',
);

const { logger } = useLogger();
const { setConfig, getConfig } = useConfig();

// Note - ommited express server serving static config EP
const configPromise: Promise<{ data: ApplicationConfig }> = import.meta.env.DEV
  ? new Promise(resolve => {
      resolve({
        data: {
          SERVER_URL: import.meta.env.VITE_SERVER_URL,
          TENANT: '',
          WEBSOCKET_URL: import.meta.env.VITE_WEBSOCKET_URL,
          REDIRECT_URL: '',
          ULA_URL: import.meta.env.VITE_ULA_URL,
        },
      });
    })
  : axios.get('/config');

configPromise.then(configResponse => {
  logger.debug('Test debug log');
  logger.info('Test info log');
  logger.warn('Test warn log');
  logger.error('Test error log');

  setConfig(configResponse.data);
  logger.debug('Current app config:', getConfig());

  const app = createApp(App);

  // /******************************************************************************
  //  * AXIOS, https://github.com/axios/axios
  //  *****************************************************************************/
  axios.defaults.baseURL = getConfig().SERVER_URL;

  /******************************************************************************
   * VUE Plugins
   *****************************************************************************/
  registerPlugins(app);

  app.config.globalProperties.$filters = filters;
  app.config.globalProperties.$http = axios;

  app.mount('#app');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.app = app;
});
