import { createStore } from 'vuex';

import VuexPersistence from 'vuex-persist';
import menu from './module/menu';
import personalSettings from './module/personalSettings';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'personalSettings', 'companies'],
});

const store = createStore({
  modules: {
    menu,
    personalSettings,
  },
  plugins: [vuexLocal.plugin],
  strict: import.meta.env.MODE !== 'production',
});

export default store;
