import { PermissionsMap } from '@/composables/auth/useAuthorization';
import { useConfig } from '@/composables/useConfig';
import { useUla } from '@/composables/useUla';
import { TFPL_ID_TOKEN } from '@/constants';
import { router } from '@/router';
import store from '@/store/store';
import { UlaUser } from '@/types/ula';
import axios from 'axios';
import { CompanyPermissionsDto } from 'generated-api/agw';
import { ref, Ref } from 'vue';

import { useWebSocket } from '@/composables/useWebSocket';
import { useActiveCompany } from '../useActiveCompany';
interface UserPayload {
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  uuid: string;
  permissions: CompanyPermissionsDto[];
  mappedPermissions: Map<string, PermissionsMap>;
}

const { setCompanies } = useActiveCompany();
const { authAndConnect, destroySocket } = useWebSocket();

/**
 * STATE
 */
const isLogged: Ref<boolean> = ref(false);
const isAccessDenied: Ref<boolean> = ref(false);
const token: Ref<string | null> = ref(null);
const initialized = ref(false);
const user: Ref<UserPayload> = ref({
  firstName: '',
  lastName: '',
  email: '',
  avatarUrl: '',
  role: '',
  uuid: '',
  permissions: [],
  mappedPermissions: new Map<string, PermissionsMap>(),
});

/**
 * Sets the logged-in user information.
 *
 * @param {Object} payload - The user profile data and token.
 * @param {string} payload.token - The authentication token.
 * @param {string} payload.displayName - The user's display name.
 * @param {string} payload.email - The user's email address.
 * @param {string} payload.photoURL - The URL of the user's avatar.
 * @param {string} payload.uuid - The user's UUID.
 * @param {Array} payload.companyPermissions - The user's company permissions.
 */
const setLoggedUser = (payload: UlaUser): void => {
  let firstName = '';
  let lastName = '';
  if (payload?.displayName) {
    const nameSplit = payload.displayName.split(' ');
    firstName = nameSplit[0];
    lastName = nameSplit[1];
  }

  const mappedPermissions = mapPermissions(payload.companyPermissions);

  token.value = payload.token;
  user.value = {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    email: payload.email,
    avatarUrl: payload.photoURL,
    uuid: payload.uuid,
    permissions: payload.companyPermissions,
    mappedPermissions,
  };

  authAndConnect(payload.token);

  axios.defaults.headers.common[TFPL_ID_TOKEN] = payload.token;
  isLogged.value = true;
  initialized.value = true;
};

/**
 * Handles login error by resetting state variables and headers.
 * - Sets isLogged to false
 * - Sets token to null
 * - Sets user to an empty object
 * - Deletes TFFL_ID_TOKEN header from axios defaults
 * @returns {void}
 */
const handleLoginError = (): void => {
  isLogged.value = false;
  token.value = null;
  user.value = {
    firstName: '',
    lastName: '',
    email: '',
    avatarUrl: '',
    uuid: '',
    permissions: [],
    mappedPermissions: new Map(),
  };
  delete axios.defaults.headers.common[TFPL_ID_TOKEN];
};

/**
 * Refreshes the login session using the provided authentication code and organization code.
 *
 * @param payload - An object containing the authentication code and organization code.
 * @param payload.authCode - The authentication code obtained from the user.
 * @param payload.organizationCode - The organization code to which the user belongs.
 * @returns A Promise that resolves to void.
 * @throws An error if login refresh fails.
 */
const refreshLogin = async (payload: UlaUser): Promise<void> => {
  try {
    isAccessDenied.value = false;
    const { setCompanies } = useActiveCompany();
    setLoggedUser(payload);
    await store.dispatch('GET_PERSONAL_SETTINGS');
    await setCompanies(payload, store.getters.personalSettings.selectedTenantCode);
  } catch (e) {
    handleLoginError();
    console.error(e);
  }
};

/**
 * Controls the authentication process for the application.
 */
export const useAuthentication = () => {
  const { logout: ulaLogout } = useUla();
  const { getConfig } = useConfig();

  /**
   * Logs out the current user.
   *
   * This function performs the following actions:
   *  1. Calls the `firebaseLogout` function to log out from the Firebase authentication system.
   *  2. Sets the `isLogged` reactive state to `false`.
   *  3. Sets the `token` reactive state to `null`.
   *  4. Resets the `user` reactive state to an empty object with default property values.
   *  5. Deletes the `TFPL_ID_TOKEN` header from the Axios library's default headers.
   *  6. Navigates the user to the login page using the `router.push` function.
   *
   * @returns {void} This function does not return any value.
   */
  const logout = (): void => {
    destroySocket();
    ulaLogout();
    isLogged.value = false;
    token.value = null;
    user.value = {
      firstName: '',
      lastName: '',
      email: '',
      avatarUrl: '',
      uuid: '',
      permissions: [],
      mappedPermissions: new Map(),
    };
    delete axios.defaults.headers.common[TFPL_ID_TOKEN];
    window.location.href = `${getConfig().ULA_URL}/?organization=${getConfig().TENANT}&application=ERP`;
  };

  const initLoggedUser = async (payloadData: UlaUser) => {
    setLoggedUser(payloadData);
    store.commit('SET_SELECTED_TENANT_CODE_SETTINGS', { tenantCode: payloadData.tenant });
    await store.dispatch('GET_PERSONAL_SETTINGS');
    await setCompanies(payloadData, store.getters.personalSettings.selectedTenantCode);
    const redirectTo = getConfig().REDIRECT_URL;
    await router.push(redirectTo ? redirectTo : '/app/home');
  };

  return {
    isLogged,
    isAccessDenied,
    initialized,
    token,
    user,
    logout,
    refreshLogin,
    initLoggedUser,
  };
};

/*
 * Function for mapping company permissions.
 */
const mapPermissions = (companyPermissions: CompanyPermissionsDto[]) => {
  const permissionMaps = new Map();

  // one map per company
  companyPermissions.forEach(permissionSet => {
    const permissionMap = new Map();

    permissionSet.permissions.forEach(subject => {
      const actions = subject.actions.map(action => action.name);
      permissionMap.set(subject.name, actions);
    });

    permissionMaps.set(permissionSet.code, permissionMap);
  });

  return permissionMaps;
};
