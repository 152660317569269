import { computed } from 'vue';
import { useDisplay as useVuetifyDIsplay } from 'vuetify';

export const useDisplay = () => {
  const { xs, sm } = useVuetifyDIsplay();
  const isMobile = computed(() => xs.value || sm.value);

  return {
    isMobile,
  };
};
