<template>
  <v-list data-cy="employee-sales-info-skills" width="100%">
    <slot />
    <v-list-item v-if="!employeeHasSkills">
      {{ $t('employee.noSkillFound') }}
    </v-list-item>

    <v-container>
      <v-row>
        <v-col v-for="skill in goodEmployeeSkillsSorted" :key="skill.collaboratorSkill.id" cols="6">
          <div v-if="skill.value < 5" class="font-weight-bold skill-item">
            {{ skill.collaboratorSkill.name }} {{ `-` }} {{ skill.value }}

            <v-progress-linear
              :model-value="getPercentSkillValue(skill.value)"
              height="15"
              rounded
              :color="getColorOnColorScale(getPercentSkillValue(skill.value))"
              :bg-opacity="0.1"
            ></v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-list>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { CollaboratorSkillValueShortOutputDto } from 'generated-api/erp/';
import { getColorOnColorScale } from '@/utils/color';

const props = defineProps<{
  collaboratorSkills?: CollaboratorSkillValueShortOutputDto[];
}>();

const goodEmployeeSkillsSorted = computed(() => {
  return props.collaboratorSkills?.filter(skill => skill.value < 5).sort(sortSkills);
});

const employeeHasSkills = computed(() => {
  if (!Array.isArray(props.collaboratorSkills)) {
    return false;
  }

  const hasSkills = props.collaboratorSkills.length > 0;
  const atLeastOneSkillIsNotAtMaxLevel = props.collaboratorSkills.some(skill => skill.value < 5);

  return hasSkills && atLeastOneSkillIsNotAtMaxLevel;
});

const getPercentSkillValue = (value: number) => {
  return (5 - value) * 25;
};

const sortSkills = (a: CollaboratorSkillValueShortOutputDto, b: CollaboratorSkillValueShortOutputDto) => {
  return a.value - b.value;
};
</script>

<style scoped>
.skill-item {
  padding: 5px;
}
</style>
