/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LeavingChecklistItemSaveDto = {
    name: string;
    type: LeavingChecklistItemSaveDto.type;
};

export namespace LeavingChecklistItemSaveDto {

    export enum type {
        INTERNAL = 'INTERNAL',
        EXTERNAL = 'EXTERNAL',
    }


}

